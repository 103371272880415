import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function CustomTimeZone({ options, onSelect ,defaultValue,onSet}: any) {
    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option: any) => {
        setSelectedOption(option);
        onSelect(option,isOpen);
        setIsOpen(false);
    };
    const setFlags=(open:any)=>{
setIsOpen(open)
onSet(open)
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>

            <div style={{
                display: 'flex',
                alignItems: 'center',
               
            }} 
            onClick={() => setFlags(!isOpen)}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }} >

                    <span style={selectedOption ? {
                        color: '#908F96',
                        fontFamily: 'Jost',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                    } : {
                        color: 'var(--Grey-Base, #939393)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}>
                        {selectedOption || 'Select Time Zone'}
                    </span>
                </div>

                <img src={require("./arrow-down.png")} style={{ marginLeft: '10px' }} />
            </div>
            {isOpen && (
                <div style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    width: '100%',
                    maxHeight: '200px', // Set max height to enable scrolling
                    overflowY: 'auto', // Enable vertical scrolling
                    backgroundColor: '#fff', /* Background color for options */
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '5px',
                    zIndex: 100
                  }}>
                    {options.map((option: any, index: any) => (
                        <div key={index} style={{
                            padding: '8px',
                            cursor: 'pointer'
                        }} onClick={() => handleOptionClick(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CustomTimeZone;