export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const headerLogo = require("../assets/header_logo.svg");
export const sideLogo = require("../assets/side_logo.svg");
export const boxheaderLogo =  require("../assets/boxheader_logo.svg");
export const emailLogo = require("../assets/email_logo.svg");
export const rightLogo = require("../assets/right_logo.svg");
export const LanguageLogo = require("../assets/langauge_logo.svg");
export const DropdownLogo = require("../assets/dropdown_logo.svg");
export const backDrop = require("../assets/backDrop.png");
export const lockLogo = require("../assets/lock_logo.svg")
export const buttonCancel = require("../assets/button_cancel.svg");