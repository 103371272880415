Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.nameRegex = /^[a-zA-Z ]*$/;
exports.emailRegex =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
exports.btnExampleTitle = "CLICK ME";
exports.getStudyApiCallIdEndPoint = "bx_block_catalogue/studies/show_study";
exports.getApiMethod = "GET";
exports.getStudyApiCallIdEndPoint="/bx_block_catalogue/studies/"
exports.addPatientRegistrationAPiEndPoint="/account_block/accounts/patients_registrations"
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.emailRegex =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.idRegex = /^[a-zA-Z0-9]+$/;
exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypeUpdateDetail = "PATCH";
exports.getAllMileStoneEndPoint = 'bx_block_appointment_management/schedules/fetch_milestones?study_id='
exports.getPatientApiEndPoint = "account_block/accounts/patients_registrations"
exports.getStudiesApiEndPoint = "bx_block_catalogue/studies/"
exports.addPatientRegistrationAPiEndPoint = "account_block/accounts/patients_registrations"
exports.patchPatientAPiEndPoint = "account_block/accounts/patients_registrations/"
exports.getTasklistAPiEndPoint = "bx_block_content_management/task_lists/attendance"
exports.patientEmailValidation="account_block/accounts/patients_registrations/check_email_presence_in_studies"
exports.getVisitScheduleAPiEndPoint = "/bx_block_appointment_management/schedules/attendance/"
// Customizable Area End