import { Typography } from '@material-ui/core';
import React, { useState } from 'react';

function CustomDropdown({ options, onSelect, icons,selectedOptionSet }: any) {
  const [selectedOption, setSelectedOption] = useState(selectedOptionSet);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>

      <div style={{
        display: 'flex',
        padding: '10px',
        border: '1px solid #ccc',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        justifyContent: 'space-between'
      }} onClick={() => setIsOpen(!isOpen)}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }} >
          <img src={require("./recurring.svg")} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


          <Typography style={selectedOption ? {
            color: '#454545',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal', marginLeft: '10px'
          } : {
            color: 'var(--Grey-Base, #939393)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal', marginLeft: '10px'
          }}>
            {selectedOption || 'Select Recurrence'}
          </Typography>
        </div>

        <img src={require("./arrow-down.png")} />
      </div>
      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: '0',
          width: '100%',
          backgroundColor: '#fff', /* Background color for options */
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          zIndex: 100
        }}>
          {options.map((option: any, index: any) => (
            <div key={index} style={{
              padding: '8px',
              cursor: 'pointer'
            }} onClick={() => handleOptionClick(option)}>
              <span style={{
                color: '#585858', fontFamily: 'Jost',
                fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                lineHeight: 'normal'
              }}>{option}</span>

            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;