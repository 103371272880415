import { BlockComponent } from "../../framework/src/BlockComponent";
import {QBConfig, QuickBlox} from "quickblox/quickblox"
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
interface PaginatedRows<T> {
  rows: T[] | T
  totalRows: number
  limit: number
  page: number
  totalPages: number
  chunkedPages: number[][]
  pagingCounter: number
  hasPrevPage: boolean
  hasNextPage: boolean
  prevPage: number
  nextPage: number
}

interface Props {
  tableData: {
    columns: {[key: string]: any}[];
    rows: {[key: string]: any}[];
  };
  navigation: any;
}

interface S {
  limit: number;
  page: number;
  paginatedRows: PaginatedRows<any> | null;
  hoverData:any;
  indexValue:any;
}

interface SS {}

class CustomDataTableController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      limit: 10,
      page: 1,
      paginatedRows: null,hoverData:"",
      indexValue:""
    };
  }

  componentDidMount = async () => {
    const result = this.paginateRows(this.props.tableData.rows, this.state.page, this.state.limit);
    this.setState({ paginatedRows: result });
  }

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<S>) => {
    if (prevState.limit !== this.state.limit || prevState.page !== this.state.page) {
      const result = this.paginateRows(this.props.tableData.rows, this.state.page, this.state.limit);
      this.setState({ paginatedRows: result });
    }
  }

  handleChangeLimit = (event: any) => {
    this.setState({ page: 1, limit: event.target.value });
  }

  handleChangePage = (page: number) => {
    this.setState({ page });
  }

  paginateRows = (
    rows: any[],
    page: number,
    limit: number
  ): PaginatedRows<any[]> => {
      // get the totalRows
      const totalRows = rows.length
      // Calculate the start and end indices for the slice
      const startIndex = (page - 1) * limit
      const endIndex = startIndex + limit

      // Use slice to extract the portion of the array
      rows = rows.slice(startIndex, endIndex)

      const totalPages = Math.ceil(totalRows / limit)

      const res = {
          rows,
          totalRows,
          limit,
          page,
          totalPages,
          chunkedPages: this.chunkArray(Array.from({length: totalPages}, (_, index) => index + 1), 5),
          pagingCounter: (page - 1) * limit + 1,
          hasPrevPage: page > 1, // Corrected condition
          hasNextPage: page < totalPages,
          prevPage: page > 1 ? page - 1 : 1, // Make sure prevPage is at least 1
          nextPage: page < totalPages ? page + 1 : totalPages,
      };
      
      return res;
  }

  chunkArray = (array: any[], chunkSize: number) => {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }

  findChunkIndex = (arrayOfArrays: number[][], number: number) => {
    for (let i = 0; i < arrayOfArrays.length; i++) {
        if (arrayOfArrays[i].includes(number)) {
            return i;
        }
    }
    return -1; // Return -1 if the number is not found in any chunk
  }
  handleMouseLeave = () => {
    this.setState({hoverData:"",indexValue:""})
  };
  handleMouseEnter = (col:any,index:any) => {
    this.setState({hoverData:col.value,indexValue:index})
   // setHoveredItem({ item: null, columnValue: null });
   console.log("col",col)
  };

  createDialogQuickBlox = (data:{'Patient Name':string, ID:string, quickBlox:string,email:string})=>{
    const QB = new QuickBlox()

    const APPLICATION_ID_2 = 103325;
    const AUTH_KEY_2 = "ak_DgCfDaNhYzuF3NU";
    const AUTH_SECRET_2 = "as_bAPcA9mH9vLgZvJ";
    const ACCOUNT_KEY_2 = "ack_djxvQgxHLG1B3PA4iQyP";
    const CONFIG_2:QBConfig = {
      // other settings
      webrtc: {
        disconnectTimeInterval: 86400,
      }
      // other settings
    };


    QB.init(APPLICATION_ID_2, AUTH_KEY_2, AUTH_SECRET_2, ACCOUNT_KEY_2, CONFIG_2);

    const userId = localStorage.getItem("userId");
    const chatToken = localStorage.getItem("chatToken");
    const studyId = localStorage.getItem("studyId");
    localStorage.setItem("quickBloxId", data.quickBlox)

    const userName = localStorage.getItem("email");
    const userUniqnueId:any = localStorage.getItem("userUniqnueId");
    const userPass = "Password@123"

    const paramsLogin = {
      login:userUniqnueId,
      password: userPass,
    };
    
    const userCredentials = {
      userId: Number(userId),
      password: String(chatToken),
    };

    QB.createSession(paramsLogin, async (error: any, result: any) => {

      QB.login(paramsLogin, (error: any, result: any) => {
        // callback function
      });
      const params = {
        type: 3,
        occupants_ids: [data?.quickBlox],
      };
      QB?.chat?.dialog?.create(params, (error:any, dialog:any)=> {
        this.props.navigation.navigate('Chat',{isSelectedUser:true, isOpen:true})

        const msg: Message = new Message(
          getName(MessageEnum.NavigationManageButtonMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.PatientMessageData), {isSelectedUser:true, isOpen:true,id:data.ID,email:data?.email, quickBloxId:data?.quickBlox, userPatientData:this.state.paginatedRows?.rows});
        this.send(msg);
      });
    });
    
    QB.chat?.connect(userCredentials, (err:any, result:any)=>{

    });
    
  }
}

export default CustomDataTableController;