// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
    Button,
    Snackbar,
    Breadcrumbs,
    Tabs,
    Tab,
    Grid,
    Select,
    MenuItem,
} from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Sidebar from "../../../components/src/Sidebar.web";
import TaskListController from "./TaskListController";
import { down, milestone } from "./assets";
import { HeaderCard, IQuestion, Question, ReponseHeaderContainer, TabLabelText } from "../../../components/src/Question.web";
import SideNavbar from "../../../components/src/SideNavbar.web";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { DoughnutChart } from "../../../components/src/DoughnutChart";
// Customizable Area End

// Customizable Area Start
export default class TaskList extends TaskListController {
    // Customizable Area Start
    renderTaskLoading = () => {
        return <></>
    }

    renderContent = () => {
        if(this.state.isViewReport){
            return this.renderViewReportMode()
        } else {
            return this.renderTaskList()
        }
    }

    renderTaskList = () => {
        return <>
            <Typography
                variant="h6"
                style={{
                    color: "#292929",
                    fontSize: 18,
                    fontWeight: 600,
                    paddingTop: 30,
                    paddingBottom : 16,
                    fontFamily: 'Inter'
                }}
            >
                Task List
            </Typography>
            {
                this.state.taskList.map(task => {
                    const isSelectedTask = task.id == this.state.taskSelected
                    return <div className="taskContainer" key={`task-${task.id}`}>
                        <div data-test-id={`taskHeaderContainer-${task.id}`} className="taskHeaderContainer" onClick={() => this.onTaskSelected(task.id)}>
                            <Typography variant="subtitle1" className="taskName">{task.attributes.task_name}</Typography>
                            {
                                isSelectedTask ? <>
                                    <Typography variant="subtitle2" className="taskPointText">{task.attributes.completed_task}/{task.attributes.total_task}</Typography>
                                    <Typography
                                        data-test-id={`viewReport`}
                                        onClick={this.handleClickViewReport}
                                        variant="subtitle2" 
                                        className="taskViewReportText">
                                            View Report
                                        </Typography>
                                </> : null
                            }
                            <img style={{rotate: isSelectedTask  ? '0deg' : '180deg'}} src={down} />
                        </div>
                        {
                            isSelectedTask ? <div className="taskDescriptionContainer">
                                <div className="taskSeparator"></div>
                                <div dangerouslySetInnerHTML={{__html: task.attributes.description}}/>
                            </div> : null
                        }
                        
                    </div>
                })
            }
        </>
    }

    renderViewReportMode = () => {
        return <div style={{marginTop: 30}}>
            <div style={{display: 'flex'}}>
                <Button
                    onClick={this.exitViewReportMode}
                    variant="outlined"
                    className="backButton"
                    startIcon={<KeyboardBackspaceIcon style={{color: 'black'}} />}
                    >
                    <Typography
                        variant="h6"
                        style={{
                            color: "#292929",
                            fontSize: 18,
                            fontWeight: 600,
                            fontFamily: 'Inter'
                        }}
                    >
                        Back
                    </Typography>
                </Button>
                <Breadcrumbs 
                    style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center', // Vertically center contents
                        paddingLeft: 16,
                        marginLeft: 16,
                        background: 'rgba(192, 192, 192, 0.2)',
                        borderRadius: 10,
                    }} 
                    separator={<ArrowRightIcon style={{color: '#A046FB'}}/>}  
                    aria-label="breadcrumb">
                        <Button>
                            <Typography className="breadCrumbItem">
                                Dashboard
                            </Typography>
                        </Button>
                        <Button>
                            <Typography className="breadCrumbItem">
                                Task List
                            </Typography>
                        </Button>
                    
                </Breadcrumbs>
            </div>

            <div style={{marginTop: 30}}>
                {this.renderTabHeader()}
                {this.renderTabQuestion()}
                {this.renderTabResults()}
            </div>
        </div>
    }

    renderTabHeader = () => {
        return(
        <Tabs
            data-test-id="container-tab"
            value={this.state.reportCurrentTab}
            onChange={this.handleCurrentTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
                style: {
                    height: 2, // Change the height of the indicator
                    backgroundColor: '#A046FB', // Change the color of the indicator
                  },
            }}
            style={{
                borderBottom: '1px solid #F0F0F0',
                textTransform: 'none',
            }}
        >
            <Tab 
                label={
                    <TabLabelText selected={this.state.reportCurrentTab == 0}>
                        Questions
                    </TabLabelText>
                } 
                style={{ minWidth: 'unset', width: 'auto', marginBottom: 8, textTransform: 'none' }} />
            <Tab 
                label={
                    <TabLabelText selected={this.state.reportCurrentTab == 1}>
                        <span>Response</span>&nbsp;&nbsp;
                            {this.state.taskList.map((task, index) => (
                                index === 0 && (
                                    <span className="tabResultText" key={`task-${task.id}`}>
                                        ({task.attributes.completed_task}/{task.attributes.total_task})
                                    </span>
                                )
                            ))}
                    </TabLabelText>
                } 
                style={{ minWidth: 'unset', width: 'auto', marginBottom: 8, textTransform: 'none' }} />
        </Tabs>
        )
    }

    renderTabQuestion = () => {
        return(
            <div
                role="tabpanel"
                hidden={this.state.reportCurrentTab !== 0}
                id={`full-width-tabpanel-0`}
                aria-labelledby={`full-width-tab-${0}`}
            >
                {this.state.questionList.map(question => {
                    return <Question key={`question:${question.id}`} data={question}/>
                })}
            </div>
        )
    }

    renderTabResults = () => {
        if(this.state.questionsWithAnwser.length > 0){
            return(
                <>
                    {this.state.taskList.map((task, index) => (
                        index === 0 && (
                <div
                    role="tabpanel"
                    key={`task-${task.id}`}
                    hidden={this.state.reportCurrentTab !== 1}
                    id={`full-width-tabpanel-1`}
                    style={{paddingBottom: 120}}
                    aria-labelledby={`full-width-tab-${1}`}
                >
                    <Typography
                        variant="h6"
                        style={{
                            color: "#292929",
                            fontSize: 18,
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            marginTop: 16,
                            marginBottom: 16,
                        }}
                    >
                        {task.attributes.completed_task} Responses
                    </Typography>
                    <ReponseHeaderContainer>
                        <HeaderCard
                            data-test-id="header-summary"
                            selected={this.state.currentResultHeaderIndex === 0} 
                            onClick={() => this.handleResultHeaderClick(0)}>
                                Summary
                        </HeaderCard>
                        <HeaderCard 
                            data-test-id="header-question"
                            style={{marginLeft: 16, marginRight: 16}}
                            selected={this.state.currentResultHeaderIndex === 1} 
                            onClick={() => this.handleResultHeaderClick(1)}>
                                Question
                        </HeaderCard>
                        <HeaderCard
                            data-test-id="header-individual"
                            selected={this.state.currentResultHeaderIndex === 2} 
                            onClick={() => this.handleResultHeaderClick(2)}>
                                Individual
                        </HeaderCard>
                    </ReponseHeaderContainer>
                    {this.renderResultTabContent()}
                </div>
                    )
                ))}
                </>
            )
        }
        return null
    }

    renderResultTabContent = () => {
        if(this.state.currentResultHeaderIndex === 0){
            return this.renderResultSummary()
        }
        if(this.state.currentResultHeaderIndex === 1){
            return this.renderResultQuestions()
        }
        if(this.state.currentResultHeaderIndex === 2){
            return this.renderResultIndividual()
        }
    }

    renderResultIndividual = () => {
        return <Grid container>
            <Grid container item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10,}}>
                <Grid item xs={12} style={{alignItems: 'center', display: 'flex', borderBottom: '1px solid rgba(192, 192, 192, 0.3)', padding: 16}}>
                    <Button data-test-id="button-previous-user" onClick={this.onPreviousUser}  disabled={this.state.currentUserIndex === 0}>
                        <KeyboardArrowLeftIcon/>
                    </Button>
                    <span><span style={{color: "#A046FB"}}>{this.state.currentUserIndex + 1}</span> of {this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers.length}</span>                    
                    <Button data-test-id="button-next-user" onClick={this.onNextUser}   disabled={this.state.currentUserIndex === this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers.length - 1} >
                        <KeyboardArrowRightIcon/>
                    </Button>
                    {this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers[this.state.currentUserIndex]?.account.id_number && (
                    <>
                    <TitleTextBold style={{marginLeft: 8, marginRight: 4 }}>
                        Patiend ID:
                    </TitleTextBold>
                    <span style={{flex: 1}}>
                        {this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers[this.state.currentUserIndex].account.id_number}
                    </span>       
                    <TitleTextBold>
                        Total Point Earned: {this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers[this.state.currentUserIndex].account.scored_points || '0'}
                    </TitleTextBold>
                    </>
                    )}
                </Grid>
                <Grid item xs={12} style={{padding: 24}}>
                    <Typography
                        style={{
                            color: "#1F3650",
                            fontSize: 32,
                            fontWeight: 700,
                        }}
                    >
                        {this.state.currentTaskName}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{marginTop: 32, marginBottom: 16, borderBottom: '1px solid rgba(192, 192, 192, 0.3)'}}>
                    <TitleTextBold>{this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers[this.state.currentUserIndex]?.account.full_name}'s Response</TitleTextBold>
            </Grid>

            {/* {this.state.questionList.map(question => { */}
            {this.state.individualQuestions.map(question => {
                const answer = this.state.usersAnswer[this.state.currentUserIndex]?.answers.filter(answer => answer.answer.question_type_id == question.id)
                return <Question key={`question:${question.id}`} answer={answer} data={question}/>
            })}
        </Grid>
    }

    renderResultQuestions = () => {
        let content = <></>
        const currentQuestion = this.state.questionsWithAnwser[this.state.currentQuestionIndex]
        if(["Subjective", "Dropdown-Based"].includes(currentQuestion?.attributes.question_type)){
            content = this.renderSubjectiveQuestion(currentQuestion)
        }
        if(currentQuestion.attributes.question_type === "Media-Based"){
            content = this.renderMediaBasedQuestion(currentQuestion)
        }
        if(currentQuestion.attributes.question_type === "MCQ" && currentQuestion.attributes.select_answer_type === "single"){
            content = this.renderSingleChoiceQuestion(currentQuestion)
        }
        if(currentQuestion.attributes.question_type === "MCQ" && currentQuestion.attributes.select_answer_type === "multiple"){
            content = this.renderMultipleChoiceQuestion(currentQuestion)
        }
        if(["Range-Based"].includes(currentQuestion.attributes.question_type)){
            content = this.renderRangeBasedQuestion(currentQuestion)
        }
        return <Grid container>
            <Grid container item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10,}}>
                <Grid item xs={12} style={{alignItems: 'center', display: 'flex', borderBottom: '1px solid rgba(192, 192, 192, 0.3)', padding: 16}}>
                    <Button data-test-id="button-previous-question"  onClick={this.onPreviousQuestion}>
                        <KeyboardArrowLeftIcon/>
                    </Button>
                    <span><span style={{color: "#A046FB"}}>{this.state.currentQuestionIndex + 1}</span> of {this.state.questionsWithAnwser.length}</span>
                    <Button data-test-id="button-next-question" onClick={this.onNextQuestion}>
                        <KeyboardArrowRightIcon/>
                    </Button>
                    <span style={{marginLeft: 16}}>
                        Press Left to Right arrow to change the questions
                    </span>
                </Grid>
                <Grid item xs={12} style={{padding: 24}}>
                    <StyledSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"       
                        value={this.state.questionsWithAnwser[this.state.currentQuestionIndex].attributes.question}
                        onChange={this.handleChangeQuestionDropdown}
                        disableUnderline
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            }}
                        >
                        <MenuItem value="" disabled>Select</MenuItem>
                        {
                            this.state.questionsWithAnwser.map(question => question.attributes.question).map(question => {
                                return (
                                    <MenuItemWithoutHover key={`dropdown-option-${question}`} value={question}>{question}</MenuItemWithoutHover>
                                )
                            })
                        }
                    </StyledSelect>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{borderBottom: '1px solid rgba(192, 192, 192, 0.3)', paddingTop: 32, paddingBottom: 16, marginBottom: 16,}}>
                <QuestionTitle>{`Question ${this.state.currentQuestionIndex + 1}: ${this.state.questionsWithAnwser[this.state.currentQuestionIndex].attributes.question}`}</QuestionTitle>
            </Grid>
            {
                content
            }
        </Grid>
    }

    renderResultSummary = () => {
        return(
            <Grid container>
                {
                    this.state.questionsWithAnwser.map((question, index) => {
                        const questionType = question.attributes.question_type
                        if(questionType === "MCQ" && question.attributes.select_answer_type === "single" || questionType === "Dropdown-Based"){
                            return this.renderSummarySingleChoiceQuestion(question, index)
                        }
                        if(questionType === "MCQ" && question.attributes.select_answer_type === "multiple" || questionType === "Range-Based"){
                            return this.renderSummaryMultipleChoiceQuestion(question, index)
                        }
                        if(questionType === "Subjective"){
                            return this.renderSummarySubjectiveQuestion(question, index)
                        }
                        if(questionType === "Media-Based"){
                            return this.renderSummaryMediaQuestion(question, index)
                        }
                    })
                }
            </Grid>
        )
    }

    renderSummarySingleChoiceQuestion = (question: IQuestion, index: number) => {
        return(
            <Grid item xs={12} style={{borderRadius: 10, background: 'rgba(192, 192, 192, 0.2)', marginTop: 32}}>
                <Grid item xs={12} style={{display: 'flex', padding: 32, borderBottom: '1px solid lightgray',}}>
                    <span style={{flex: 1}}>Question {index + 1}: {question.attributes.question}</span>
                    {this.state.taskList.map((task, index) => (
                        index === 0 && (
                            <span className="tabResultText" key={`task-${task.id}`}>
                                {task.attributes.completed_task} Responses
                            </span>
                        )
                    ))}
                </Grid>
                <DoughnutChart data={this.calculateDataset(question)}/>
            </Grid>
        )
    }

    renderSummarySubjectiveQuestion = (question: IQuestion, index: number) => {
        return(
            <Grid item xs={12} style={{borderRadius: 10, background: 'rgba(192, 192, 192, 0.2)', marginTop: 32}}>
                <Grid item xs={12} style={{display: 'flex', padding: 32, borderBottom: '1px solid lightgray',}}>
                    <span style={{flex: 1}}>Question {index + 1}: {question.attributes.question}</span>
                    <span>{question.answers.length} Responses</span>
                </Grid>
                {question.answers.map(item => {
                    return <p style={{margin: 32, padding: 16, borderRadius: 5, background: 'white',}}>
                        <span>{item.answer.answer_text}</span>
                    </p>
                })}
            </Grid>
        )
    }

    renderSummaryMediaQuestion = (question: IQuestion, index: number) => {
        return(
            <Grid item xs={12} style={{borderRadius: 10, background: 'rgba(192, 192, 192, 0.2)', marginTop: 32}}>
                <Grid item xs={12} style={{display: 'flex', padding: 32, paddingBottom: 0}}>
                    <span style={{flex: 1}}>Question {index + 1}: {question.attributes.question}</span>
                    <span>{question.answers.length} Responses</span>
                </Grid>
                <Grid item xs={12} style={{padding: 32, borderBottom: '1px solid lightgray',}}>
                    <img 
                        style={{width: 100, height: 100}} 
                        src={question.attributes.image}
                    />
                </Grid>
                <div style={{margin: 16,  maxWidth: '80vw', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                    {[...question.answers, ...question.answers, ...question.answers, ...question.answers].map(item => {
                        return <img src={item.answer.media_urls[0]} style={{ width: "100px", height: "100px", margin: 16, display: 'inline-block' }}/>
                    })}
                </div>

            </Grid>
        )
    }

    renderSummaryMultipleChoiceQuestion = (question: IQuestion, index: number) => {
        let data: any = {}
        if(question.attributes.question_type === "MCQ"){
            data = this.processAnswerListSubjective(question.answers)
            question.attributes.options.forEach(option => {
                if(!data[option.option_name]){
                    data[option.option_name] = 0
                }
            });
        } else {
            data = this.processAnswerListRangeBased(question.answers)
        }
        const formatedMultipleChoiceResult = Object.keys(data).map((key) => {
            return {
                "option": key,
                "answerNumber": data[key]
            }
        })
        let mostAnswer = formatedMultipleChoiceResult[0].answerNumber
        formatedMultipleChoiceResult.forEach(item => {
            if(item.answerNumber > mostAnswer){
                mostAnswer = item.answerNumber
            }
        })
        
        return(
            <Grid item xs={12} style={{borderRadius: 10, background: 'rgba(192, 192, 192, 0.2)', marginTop: 32}}>
                <Grid item xs={12} style={{display: 'flex', padding: 32, borderBottom: '1px solid lightgray',}}>
                    <span style={{flex: 1}}>Question {index + 1}: {question.attributes.question}</span>
                    <span>{question.answers.length} Responses</span>
                </Grid>
                <Grid item xs={8} style={{ padding: 32 }}>
                  {
                    formatedMultipleChoiceResult.map(answerFormated => {
                    const calculatedWidth = answerFormated.answerNumber / mostAnswer * 100
                    const hasNoAnswer = answerFormated.answerNumber === 0
                      return (
                        <Grid item container xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'start',gap: '20px',flexWrap: 'wrap'}}>
                          <Grid item xs={2} sm={1} style={{minWidth: 30}}>
                            <span>{answerFormated.option}</span>
                          </Grid>
                          <div style={{width: 1, height: 40, background: 'lightgray', marginLeft:'15px'}} />
                              <Grid item xs={10} sm={7} style={{ height: 30,}}>
                            <div style={{
                                background: hasNoAnswer ? 'gray' : 'rgba(160, 70, 251, 1)',
                                width: hasNoAnswer ? "2px" : `${calculatedWidth}%`,
                                height: '100%'
                            }}/>
                          </Grid>
                            <Grid item xs={12} sm={'auto'} style={{marginLeft: 16}}>
                                  <span>{answerFormated.answerNumber} Responses</span>
                            </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
            </Grid>
        )
    }

    calculateDataset = (question: any) => {
        console.log(question)
        const labels = question.attributes.options.map((option: any) => {
          return option.option_name
        })
        const data: number[] = []
        labels.map((label: any) => {
          let count = 0;
          question.answers.forEach((answer: any) => {
            if(answer.answer.answer_text === label){
              count++
            }
          });
          data.push(count)
        })
        const backgroundColors = [
          'rgba(160, 70, 251, 1)',
          'rgba(160, 70, 251, 0.6)',
          'rgba(160, 70, 251, 0.8)',
          'rgba(160, 70, 251, 0.4)',
          'rgba(160, 70, 251, 0.2)',
          'gray'
        ]
        const dataSet = {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: backgroundColors,
            borderWidth: 1
          }]
        };
        return dataSet
      }

    renderSubjectiveQuestion = (currentQuestion: IQuestion) => {
        const formatedResult = this.processAnswerListSubjective(currentQuestion.answers)
        return(
            <>
                {Object.keys(formatedResult).map(answer => {
                    return(
                        <Grid key={answer} item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10, marginTop: 16,}}>
                            <div style={{borderBottom: '1px solid rgba(192, 192, 192, 0.3)', margin: 16, paddingBottom: 16}}>
                                <AnswerText>{answer}</AnswerText>
                            </div>
                            <div style={{margin: 16,}}>
                                <AnswerNumber>{formatedResult[answer]} Responses</AnswerNumber>
                            </div>
                        </Grid>
                    )
                })}
            </>
        )
    }

    renderRangeBasedQuestion = (currentQuestion: IQuestion) => {
        const formated = this.processAnswerListRangeBased(currentQuestion.answers)
        return(
            <>
                {Object.keys(formated).map(answerFormated => {
                    return(
                        <Grid key={answerFormated} item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10, marginTop: 16,}}>
                            <div style={{borderBottom: '1px solid rgba(192, 192, 192, 0.3)', margin: 16, paddingBottom: 16}}>
                                <AnswerText>{answerFormated}</AnswerText>
                            </div>
                            <div style={{margin: 16,}}>
                                <AnswerNumber>{formated[answerFormated]} Responses</AnswerNumber>
                            </div>
                        </Grid>
                    )
                })}
            </>
        )
    }

    renderMediaBasedQuestion = (currentQuestion: IQuestion) => {
        return(
            <>
                {currentQuestion.answers.map(answer => {
                    return(
                        <Grid key={answer.answer.media_urls[0]} item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10, marginTop: 16,}}>
                            <img src={answer.answer.media_urls[0]} style={{ width: "100px", height: "100px", margin: 16 }}/>
                        </Grid>
                    )
                })}
            </>
        )
    }

    renderSingleChoiceQuestion = (currentQuestion: IQuestion) => {
        const formatedResult = this.processAnswerListSubjective(currentQuestion.answers)
        return(
            <>
            {Object.keys(formatedResult).map(answer => {
                return(
                    <Grid key={answer} item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10, marginTop: 16,}}>
                        <div style={{borderBottom: '1px solid rgba(192, 192, 192, 0.3)', display: 'flex', alignItems: 'center', margin: 16, paddingBottom: 16}}>
                            <RadioButtonCheckedIcon  style={{ color: "#A046FB", marginRight: 16 }}/>
                            <AnswerText>{answer}</AnswerText>
                        </div>
                        <div style={{margin: 16,}}>
                            <AnswerNumber>{formatedResult[answer]} Responses</AnswerNumber>
                        </div>
                    </Grid>
                )
            })}
        </>
        )
    }

    renderMultipleChoiceQuestion = (currentQuestion: IQuestion) => {
        const formatedResult = this.processAnswerListMultipleChoice(currentQuestion.answers)
        return(
            <>
            {Object.keys(formatedResult).map(combineAnswer => {
                return(
                    <Grid key={combineAnswer} item xs={12} style={{background: 'rgba(192, 192, 192, 0.2)', borderRadius: 10, marginTop: 16,}}>
                        {combineAnswer.split("---").map(answer => {
                            return(
                                <div key={`answer-${answer}`} style={{borderBottom: '1px solid rgba(192, 192, 192, 0.3)', display: 'flex', alignItems: 'center', margin: 16, paddingBottom: 16}}>
                                    <CheckBoxOutlinedIcon style={{ color: "#A046FB", marginRight: 16 }}/>
                                    <AnswerText>{answer}</AnswerText>
                                </div>
                            )
                            })
                        }
                        <div style={{margin: 16,}}>
                            <AnswerNumber>{formatedResult[combineAnswer]} Responses</AnswerNumber>
                        </div>
                    </Grid>
                )
            })}
        </>
        )
    }

    renderSnackbar = () => {
        return(
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={this.state.openSnackbar}
                autoHideDuration={6000}
                message={this.state.snackbarContent}
                onClose={this.handleCloseSnackbar}
            />
        )
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            // Customizable Area Start
            <>
                <SideNavbar
                    navigation={this.props.navigation}
                    id={this.props.id}
                    showStudy={true}
                    showButtonChats
                    showButtonNotification
                />
                
               
                <ContainerBox>
                    <Sidebar
                        navigation={this.props.navigation}
                        id={this.props.id}
                        selected="task_list"
                    />
                <Grid container >
                <Grid item xs={10} sm={9}>
                    <Box style={{ flex: 1, padding:'30px'}}>
                        <Typography
                            style={{
                                color: "#1F3650",
                                marginBottom: 30,
                                fontFamily: "Jost",
                                fontSize: "40px",
                                fontWeight: 700,
                                lineHeight: "50px",
                                textAlign: "left",
                            }}
                        >
                            Task List
                        </Typography>

                        <Typography
                            style={{
                                color: "#414141",
                                marginBottom: 50,
                                fontFamily: "Jost",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "26px",
                                textAlign: "left"
                            }}
                        >
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s,
                        </Typography>

                        <div style={{ position: 'relative', width: '100%' }}>
                            <div
                                data-test-id='GetMilestone'
                                style={{
                                    display: 'flex',
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    justifyContent: 'space-between'
                                }}
                                onClick={() => this.setState(prev => {
                                    return {
                                        openMilestoneOptions: !prev.openMilestoneOptions
                                    }
                                })}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <img src={milestone} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


                                    <span style={this.state.selectedMilestone ? {
                                        color: '#454545',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                    } : {
                                        color: 'var(--Grey-Base, #939393)',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                    }}>
                                        {this.state.selectedMilestone || 'Select Milestone'}
                                    </span>
                                </div>

                                <img src={down} />
                            </div>
                            {
                                this.state.openMilestoneOptions ? <div style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: '0',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '5px', borderTop: 'none', zIndex: 100,
                                    marginBottom: '20px',
                                    maxHeight: '250px',
                                    overflow: 'auto'
                                }}>
                                    {
                                        this.state.milestoneOptions.map(milestone => {
                                            return <div
                                                    data-test-id={`milestone-${milestone.id}`}
                                                    key={`milestone${milestone.id}`} 
                                                    className="milestoneContainer" 
                                                    onClick={() => this.onMilestoneSelected(milestone)}>
                                                <span>{milestone.name}</span>
                                            </div>
                                        })
                                    }
                                </div> : null
                            }
                        </div>

                        {
                            this.state.isLoadingTaskList ? this.renderTaskLoading() : this.renderContent()
                        }
                    </Box>
                    {this.renderSnackbar()}
                </Grid>
                <Grid item xs={2} sm={3} >
                </Grid>
                </Grid>
                </ContainerBox>

            </>
            // Customizable Area End
        );
    }
    // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const ContainerBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    "& .milestoneContainer": {
        paddingTop: "16px",
        paddingBottom: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    "& .milestoneContainer:hover": {
        backgroundColor: '#F0F0F0',
        cursor: 'pointer', // Optional: Change cursor on hover
    },
    "& span": {
        fontFamily: "Inter",
    },
    "& p": {
        fontFamily: "Inter",
    },
    "& .taskContainer": {
        background: '#F0F0F0',
        marginBottom: 16,
        borderRadius: 10
    },
    "& .taskContainer:hover": {
        backgroundColor: '#F0F0F0',
        cursor: 'pointer', 
    },
    "& .taskPointText": {
        color: '#A046FB',
        marginLeft: 16,
        marginRight: 8,
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: 15,
    },
    "& .taskViewReportText": {
        color: '#A046FB',
        textDecoration: 'underline',
        marginLeft: 8,
        marginRight: 24,
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: 15,
    },
    "& .taskName": {
        flex: 1, 
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: 16,
        color: '#292929'
    },
    "& .taskHeaderContainer": {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
    },
    "& .taskSeparator": {
        flex: 1, 
        height: 1, 
        background: 'rgba(192, 192, 192, 0.3)', 
        marginBottom: 16
    },
    "& .taskDescriptionContainer": {
        paddingLeft: 16,
        paddingBottom: 8,
        paddingRight: 16
    },
    "& .backButton": {
        borderRadius: 5,
        border: "2px solid #A046FB",
        textTransform: 'none'
    },
    "& .breadCrumbItem": {
        fontSize: 16,
        fontWeight: 500,
        color: 'rgba(192, 192, 192, 0.9)',
        textTransform: 'none'
    },
    "& .tabResultText": {
        fontSize: 16,
        fontWeight: 600,
        color: '#A046FB',
        textTransform: 'none'
    }
});

const StyledSelect = styled(Select)({
    width: '100%',
    minHeight: 50,
    paddingLeft: 16,
    paddingRight: 16,
    background: 'white',
    borderRadius: 5,
    border: '1px solid rgba(192, 192, 192, 0,3)',
    '& .MuiSelect-select': {
        borderBottom: 'none', // Remove the underline
    },
    '& :focus': {
        background: 'white',
        backgroundColor: 'white'
    },
});

const MenuItemWithoutHover = styled(MenuItem)({
    '&:hover': {
        backgroundColor: 'transparent', // Disable hover effect,
        background: 'white'
    },
    '&:focus': {
        backgroundColor: 'transparent', // Disable hover effect,
        background: 'white'
    }
})

const QuestionTitle = styled(`span`)({
    fontWeight: 700,
    fontFamily: 'Inter',
    color: 'black',
    fontSize: 16,
})

const AnswerText = styled(`span`)({
    fontWeight: 700,
    fontFamily: 'Inter',
    color: 'black',
    fontSize: 15,
})

const AnswerNumber = styled(`span`)({
    fontWeight: 500,
    fontFamily: 'Inter',
    color: 'gray',
    fontSize: 15,
})

const TitleTextBold = styled(`span`)({
    fontFamily: "Inter", 
    fontWeight: 700, 
    color: 'black'
})
// Customizable Area End
