import { BlockComponent } from "../../framework/src/BlockComponent";

interface Props {
  text: string,
  onUpload: (file: File) => void
}

interface S {}

interface SS {}

class GenericFileUploadButtonController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      this.props.onUpload(file);
    }
  }
}

export default GenericFileUploadButtonController;