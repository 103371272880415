import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { changeLogo, contactLogo, logoutLogo, shieldLogo, techHelp, termsLogo } from "../../blocks/customisableuserprofiles2/src/assets";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { QuickBlox } from "quickblox/quickblox"
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showStudy: boolean;
  showButtonChats?: boolean;
  showButtonNotification?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  detail: any
  open: boolean,
  passwordDialogOpen: boolean;
  editProfile: boolean;
  expanded: any;
  termsDialogOpen: boolean;
  privacyDialogOpen: boolean;
  techBarOpen: boolean;
  contactBarOpen: boolean;
  requestAdmin: boolean;
  oldPassword: any,
  newPassword: any,
  newConfirmPassword: any,
  oldPasswordError: any,
  newPasswordError: any,
  newConfirmPasswordError: any,
  newPasswordSuccess: boolean;
  oldEmailAddress: any,
  newEmailAddress: any,
  newConfirmEmailAddress: any,
  oldEmailAddressError: any,
  newEmailAddressError: any,
  newConfirmEmailAddressError: any,
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
  phoneNumber: any,
  phoneNumberError: any,
  fullName: any,
  fullNameError: any
  userEmail: any,
  userEmailError: any,
  contactFullName: any,
  contactFullNameError: any,
  contactUserEmail: any,
  contactUserEmailError: any,
  selectedReason: any,
  selectError: any,
  contactUserMessage: any,
  userDetails: any,
  termsAndConditions: any,
  privacyPolicy: any,
  technicalHelpData: any,
  showOldPassword: boolean,
  showNewPassword: boolean,
  showNewConfirmPassword: boolean,
  stdInput: any,
  studyData: any,
  searchQuery: any,
  selectedStudy: any,
  studyDrawerOpen: boolean,
  profileImage: string,
  profileImageError: string,
  selectedStudyName: any,
  logoutFlag: boolean,
  notificationDrawerFlag: boolean
  notificationResponce: any,
  showCheckbox: boolean,
  checkedValue: boolean,
  tokenExpired:boolean,
  idList: any,
  selectdPaper: boolean,
  selectedValue:string,
  showDiv:boolean,
  isRotated:boolean,
  // isPhoneNumberValid: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SideNavbarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  getTermsandConditionApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  getFaqApiCallId: string = "";
  contactFormApiCallId: string = "";
  editUserProfileApiCallId: string = "";
  changePasswordApiCallId: string = "";
  changeEmailApiCallId: string = "";
  getStudyIndexApiCallId: string = "";
  getAllNotificationApiCallId: string = "";
  getNotificationDeleteApiCallID: string = "";
  putNotificationMarkASRead:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      detail: [{
        dName: 'Change Password',
        id: "change-password",
        dImg: changeLogo,
      },
      {
        dName: 'Terms and Conditions',
        id: "terms-condt",
        dImg: termsLogo
      },
      {
        dName: 'Privacy Policies',
        id: "privacy-policy",
        dImg: shieldLogo
      },
      {
        dName: 'Technical Help',
        id: "tech-help",
        dImg: techHelp
      },
      {
        dName: 'Contact Us',
        id: "contact-us",
        dImg: contactLogo
      },
      {
        dName: 'Logout',
        id: "logout",
        dImg: logoutLogo
      }
      ],
      open: false,
      passwordDialogOpen: false,
      editProfile: false,
      expanded: false,
      termsDialogOpen: false,
      privacyDialogOpen: false,
      techBarOpen: false,
      contactBarOpen: false,
      requestAdmin: false,
      oldPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      newConfirmPasswordError: "",
      newPasswordSuccess: false,
      oldEmailAddress: '',
      newEmailAddress: '',
      newConfirmEmailAddress: '',
      oldEmailAddressError: '',
      newEmailAddressError: '',
      newConfirmEmailAddressError: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      phoneNumber: null,
      phoneNumberError: '',
      fullName: '',
      fullNameError: '',
      userEmail: '',
      userEmailError: '',
      contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      contactUserMessage: '',
      userDetails: null,
      termsAndConditions: null,
      privacyPolicy: null,
      technicalHelpData: null,
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
      stdInput: '',
      studyDrawerOpen: false,
      selectedStudy: null,
      searchQuery: '',
      studyData: null,
      profileImage: '',
      profileImageError: '',
      selectedStudyName: "",
      logoutFlag: false,
      notificationDrawerFlag: false,
      notificationResponce: [],
      showCheckbox: false,
      checkedValue: true,
      tokenExpired:false,
      idList: [],
      selectdPaper: false,
      selectedValue: 'English',
      showDiv: true,
      isRotated: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const handleRestApiResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null && responseJson !== undefined) {
          switch (apiRequestCallId) {
            case this.getUserProfileApiCallId:
              this.callResponceOfProfile(responseJson)
              break;
            case this.getTermsandConditionApiCallId:
              this.setState({ termsAndConditions: responseJson.data.description });
              break;
            case this.getPrivacyPolicyApiCallId:
              this.setState({ privacyPolicy: responseJson.data.description });
              break;
            case this.getFaqApiCallId:
              this.setState({ technicalHelpData: responseJson.data });
              break;
            case this.editUserProfileApiCallId:
              this.getUserProfile()
              break;
            case this.getStudyIndexApiCallId:
              this.setState({ studyData: responseJson?.studies.data });
              break;
            case this.getAllNotificationApiCallId:
              this.setState({ notificationResponce: responseJson.notifications })
              break;
            case this.getNotificationDeleteApiCallID:
              this.callDeleteResponce(responseJson)
              case this.putNotificationMarkASRead:
              this.callDeleteResponce(responseJson)
            default:
          }
        }
      }
    };

    handleRestApiResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getUserProfile()
    this.getStudyIndexDetail();

    let studyNameStore = await getStorageData("studyName")
    if (studyNameStore) {
      let parseData = JSON.parse(studyNameStore)
      this.setState({ selectedStudy: parseData?.id, selectedStudyName: parseData?.attributes?.study_name })
    }
  }
  handleckedvalue=(option:any)=>{
    if (this.state.idList.includes(option)) {
      return true
  }
  else {
      return false
  }
  }
  handleCheckboxChange = (option: any,e:any) => {
    if (this.state.idList) {
      const foundObject1 = this.state.idList.find((obj1: { id: any; }) => obj1.id === option.id);
      if (foundObject1) {
        this.setState({ idList: this.state.idList.filter((item: any) => item !== option) ,
          checkedValue:e.target.value})

      } else {
        this.setState({ idList: [...this.state.idList, option] ,  checkedValue:e.target.value})
      }
    }
  }
  handleLanguageSelect = (language:string) => {
    this.setState(prevState => ({
      selectdPaper: !prevState.selectdPaper,
        selectedValue: language, 
        showDiv: true,         
        isRotated: false       
    }));
};
  handleClick = () => {
    this.setState((prevState) => ({
        isRotated: !prevState.isRotated,
        showDiv: !prevState.showDiv,
    }));
};
  handleSelectChange = (event: { target: { value: unknown } })=> {
    this.setState({ selectedValue: event.target.value as string });
  };
  handleLanguageSelected = () => {
    this.setState(prevState => ({
        selectdPaper: !prevState.selectdPaper,
        isRotated: !prevState.isRotated, 
    }));
};

  callDeleteResponce = (responseJson: any) => {
    this.setState({showCheckbox:false})
    this.callNotificationApi()
  }
  handleSelectAll() {
   
    this.setState({ idList: this.state.notificationResponce, });
}

  handleMarkAsRead = () => {
    let token = localStorage.getItem("token");
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        "token": token
    };
    let idString = "";
    if (this.state.idList) {
        idString = this.state.idList.map((obj: { id: any; }) => obj.id).join(',');
    }
    const httpBody = new FormData();
    httpBody.append("notification_ids[]", idString);

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putNotificationMarkASRead = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllNotificationMarkAsReadApiEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    // Optimistically update local state
    this.setState(prevState => {
      const updatedNotifications = prevState.notificationResponce.map((notification:any) => {
          if (this.state.idList.some((idObj:any) => idObj.id === notification.id)) {
              return { ...notification, is_read: true };
          }
          return notification;
      });
      return { notificationResponce: updatedNotifications, idList: [] };
  });
}

  handleRemove = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    let idString = "";
    if (this.state.idList) {
      idString = this.state.idList.map((obj: { id: any; }) => obj.id).join(',');
    }
    const httpBody = new FormData();

    httpBody.append("notification_ids[]", idString)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationDeleteApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdeleteNotificationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAllAppointmentsAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCloseDialog = () => {
    this.setState({ tokenExpired: false });
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  callResponceOfProfile = (responseJson: any) => {
    let addressBreakdown = "";
    if (responseJson.errors?.is_token_expired) {
      this.setState({tokenExpired:true});
      return;
    }
    if (responseJson.data.attributes?.address) {
      let address = responseJson.data.attributes?.address; // Example string with extra comma at the end
      address = address.trim().replace(/,\s*$/, ''); // Remove extra comma at the end
      addressBreakdown = address.split(',');
    }
    this.setState({
      userDetails: responseJson.data.attributes,
      fullName: responseJson.data.attributes.full_name,
      userEmail: responseJson.data.attributes.email,
      phoneNumber: responseJson.data.attributes.full_phone_number,
      profileImage: responseJson.data.attributes.profile_image,
      addressLine1: addressBreakdown[0],
      addressLine2: addressBreakdown[1],
      addressLine3: addressBreakdown[2]
    })
    localStorage.setItem("quickBloxId", responseJson.data.attributes.quickblox_id)
    localStorage.setItem("user_name", responseJson.data.attributes.full_name)

  }
  handleDrawerOpen = () => {
    this.setState({
      open: true, notificationDrawerFlag: false
    });
  };
  handleNotificationDrawer = () => {
    this.setState({ notificationDrawerFlag: true, open: false })
    this.callNotificationApi()
  }
  callNotificationApi = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotificationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleNotificationDrawerClose = () => {
    this.setState({
      notificationDrawerFlag: false
    })
  }
  callFormatedDate = (date1: any) => {
    const dateTime = moment(date1);

    dateTime.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });

    const formattedDateTime = dateTime.format('DD/MM/YYYY [at] h:mm A');
    return formattedDateTime
  }
  handleDrawerClose = () => {
    this.setState({
      open: false,
      editProfile: false,
      contactBarOpen: false,
      studyDrawerOpen: false,
      fullNameError: '',
      userEmailError: '',
      phoneNumberError: '',
      contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      techBarOpen: false
    });
  };

  handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleClickOpen = (name: string) => {
    switch (name) {
      case 'Change Password':
        this.setState({ passwordDialogOpen: true, open: false });
        break;
      case 'Request Admin':
        this.setState({ requestAdmin: true, open: false });
        break;
      case 'Terms and Conditions':
        this.setState({ termsDialogOpen: true, open: false });
        this.getTermsAndCondition();
        break;
      case 'Privacy Policies':
        this.setState({ privacyDialogOpen: true, open: false });
        this.getPrivacyPolicy();
        break;
      case 'Technical Help':
        this.setState({ techBarOpen: true });
        this.getFaqs();
        break;
      case 'Contact Us':
        this.setState({ contactBarOpen: true });
        break;
      case 'Logout':
        this.setState({ logoutFlag: true })

        break;
      default:
        break;
    }
  };

  handleClose = (name: string) => {
    switch (name) {
      case 'Change Password':
        this.setState({
          passwordDialogOpen: false, open: true,
          oldPassword: "", newPassword: "", newConfirmPassword: "",
          oldPasswordError: '', newPasswordError: '', newConfirmPasswordError: '',
          showOldPassword: false,
          showNewPassword: false,
          showNewConfirmPassword: false,
        });
        break;
      case 'Request Admin':
        this.setState({
          requestAdmin: false, open: true,
          oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '',
          oldEmailAddressError: '', newEmailAddressError: '', newConfirmEmailAddressError: '',
          fullNameError: '', userEmailError: '', phoneNumberError: ''
        });
        break;
      case 'Terms and Conditions':
        this.setState({ termsDialogOpen: false, open: true });
        break;
      case 'Privacy Policies':
        this.setState({ privacyDialogOpen: false, open: true });
        break;
      case 'Technical Help':
        this.setState({ techBarOpen: false, open: true });
        break;
      case 'Contact Us':
        this.setState({
          contactBarOpen: false, open: true, contactFullName: '',
          contactFullNameError: '',
          contactUserEmail: "",
          contactUserEmailError: "",
          selectedReason: '',
          selectError: '',
          contactUserMessage: '',
        });
        break;
      default:
        break;

    }
  }

  handleLogout = () => {
    localStorage.clear()
    this.props.navigation.navigate('EmailAccountRegistration')
  }

  handleOldPasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let oldPasswordError = '';
    if (value !== '') {
      oldPasswordError = strongPasswordRegex.test(value) ? '' : 'Old password is invalid!';
    }
    this.setState({
      oldPassword: value,
      oldPasswordError: oldPasswordError
    });
  }

  handleNewPasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let newPasswordError = '';
    if (value !== '') {
      newPasswordError = strongPasswordRegex.test(value)
        ? ''
        : 'Password must contain 1 Uppercase, 1 Lowercase, 1 Special and 1 Numeric Characters!';
    }
    this.setState({
      newPassword: value,
      newPasswordError: newPasswordError,
      newConfirmPasswordError: value === this.state.newConfirmPassword ? '' : this.state.newConfirmPasswordError,
    });

  }

  handleNewConfirmPasswordChange = (value: any) => {
    this.setState({
      newConfirmPassword: value,
      newConfirmPasswordError: this.state.newPassword === value ? '' : 'Password do not match',
    });
  }
  /* istanbul ignore next */
  newSubmitPassWord = async () => {
    const currentPassword = await getStorageData("currentPassword")

    if (this.state.oldPassword === "") {
      this.setState({ oldPasswordError: "please enter old password" })
      return
    }

    if (this.state.newPassword === "") {
      this.setState({ newPasswordError: "please enter password" })
      return
    }

    if (this.state.newConfirmPassword === "") {
      this.setState({ newConfirmPasswordError: "please enter confirm password" })
      return
    }

    if (this.state.newPassword !== this.state.newConfirmPassword) {
      this.setState({ newConfirmPasswordError: "Password do not match" })
      return
    }

    if (this.state.oldPassword !== currentPassword) {
      this.setState({ oldPasswordError: "Your input does not match to your old/current password" })
      return
    }

    this.changePassword({ current_password: this.state.oldPassword, new_password: this.state.newPassword })
    await setStorageData("currentPassword", this.state.newPassword)
    this.setState({ open: true, passwordDialogOpen: false, oldPassword: "", newPassword: "", newConfirmPassword: "" });
    return
  }

  handleOldEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    this.setState({
      oldEmailAddress: value,
      oldEmailAddressError: strongEmailRegex.test(value)
        ? ""
        : "Old Email is invalid!"
    })
  }

  handleNewEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    this.setState({
      newEmailAddress: value,
      newEmailAddressError: strongEmailRegex.test(value)
        ? ""
        : "Email must be valid!"
    })
  }

  handleNewConfirmEmailAddress = (value: any) => {
    this.setState({
      newConfirmEmailAddress: value,
      newConfirmEmailAddressError: this.state.newEmailAddress === value ? "" : 'Email does not match!'
    })
  }

  newSubmitEmailAddress = () => {
    if (this.state.oldEmailAddress === "") {
      this.setState({ oldEmailAddressError: 'please enter your old email' })
      return
    }

    if (this.state.newEmailAddress === "") {
      this.setState({ newEmailAddressError: 'please enter your new email' })
      return
    }

    if (this.state.newConfirmEmailAddress === "") {
      this.setState({ newConfirmEmailAddressError: 'please enter email confirmation' })
      return
    }

    if (this.state.newEmailAddress !== this.state.newConfirmEmailAddress) {
      this.setState({ newConfirmEmailAddressError: 'Email does not match!' })
      return
    }

    if (this.state.oldEmailAddress !== this.state.userEmail) {
      this.setState({ oldEmailAddressError: 'Your input does not match your current/old email' })
      return
    }


    this.changeEmail({ current_email: this.state.oldEmailAddress, new_email: this.state.newEmailAddress })
    this.setState({ open: true, requestAdmin: false, oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '', userEmail: this.state.newEmailAddress })
    return
  }

  handleFullNameEdit = (event: any) => {
    const strongNameRegex = configJSON.nameRegex;
    const newFullName = event.target.value;
    const isLengthValid = newFullName.length > 2;

    this.setState({
      fullName: newFullName,
      fullNameError: strongNameRegex.test(newFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handleUserNewEmail = (event: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    const newUserEmail = event.target.value;

    this.setState({
      userEmail: newUserEmail,
      userEmailError: strongEmailRegex.test(newUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  handlePhoneNumberChange = (event: any) => {
    const strongPhoneNumberRegex = configJSON.phoneNumberRegex;
    const newPhoneNumber = event.target.value;

    this.setState({
      phoneNumber: newPhoneNumber,
      phoneNumberError: strongPhoneNumberRegex.test(newPhoneNumber)
        ? ""
        : 'Please enter a valid phone number'
    });
  };

  handleContactFullName = (event: any) => {
    const strongNameRegex = configJSON.nameRegex;
    const newContactFullName = event.target.value;
    const isLengthValid = newContactFullName.length > 2;

    this.setState({
      contactFullName: newContactFullName,
      contactFullNameError: strongNameRegex.test(newContactFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handleContactUserEmail = (event: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    const newContactUserEmail = event.target.value;

    this.setState({
      contactUserEmail: newContactUserEmail,
      contactUserEmailError: strongEmailRegex.test(newContactUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  handleDropdownChange = (event: any) => {
    const newSelectedReason = event.target.value;
    const isError = newSelectedReason === '';

    this.setState({
      selectedReason: newSelectedReason,
      selectError: isError ? "Please select a reason" : ""
    });
  };
  handleUpdateDetailsClick = () => {

    const { fullName, userEmail, phoneNumber, addressLine1, addressLine2, addressLine3 } = this.state;
    const isFullNameValid = configJSON.nameRegex.test(fullName) && fullName.length > 2;
    const isEmailValid = configJSON.emailRegex.test(userEmail);
    const isPhoneNumberValid = configJSON.phoneNumberRegex.test(phoneNumber);

    this.setState({
      fullNameError: isFullNameValid ? "" : 'Please enter a valid full name',
      userEmailError: isEmailValid ? "" : 'Please enter a valid email',
      phoneNumberError: isPhoneNumberValid ? "" : 'Please enter a valid phone number',
    });

    if (isFullNameValid && isEmailValid && isPhoneNumberValid) {
      let address = ""
      if (addressLine1 && addressLine1.length > 0) {
        address = addressLine1
      }
      if (addressLine2 && addressLine2.length > 0) {
        address = address + ", " + addressLine2
      }
      if (addressLine3 && addressLine3.length > 0) {
        address = address + ", " + addressLine3
      }
      this.editUserProfile({
        full_name: fullName,
        full_phone_number: phoneNumber,
        address: address
      })
      this.setState({
        editProfile: false, open: true
      })
    }
  };
  /* istanbul ignore next */
  handleContactSubmitButton = () => {
    const { contactFullName, contactUserEmail, selectedReason, contactUserMessage } = this.state

    const isContactFullNameValid = configJSON.nameRegex.test(contactFullName) && contactFullName.length > 2;
    const isContactUserEmailValid = configJSON.emailRegex.test(contactUserEmail);
    const isReasonValid = selectedReason !== ''

    this.setState({
      contactFullNameError: isContactFullNameValid ? "" : 'Please enter a valid full name',
      contactUserEmailError: isContactUserEmailValid ? "" : 'Please enter a valid email',
      selectError: isReasonValid ? "" : 'Please select a reason',
    });

    if (isContactFullNameValid && isContactUserEmailValid && selectedReason) {
      this.contactForm({
        name: contactFullName,
        email: contactUserEmail,
        reason: selectedReason,
        message: contactUserMessage
      })
      this.setState({
        contactBarOpen: false, open: true, contactFullName: '', contactUserEmail: '', selectedReason: '', contactUserMessage: ''
      })
    }
  }

  getTermsAndCondition = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsandConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaqs = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfile = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAccountApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changePassword = ({ current_password, new_password }: { current_password: string, new_password: string }) => {
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    }

    const httpBody = {
      data: {
        "current_password": current_password,
        "new_password": new_password
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeEmail = ({ current_email, new_email }: { current_email: string, new_email: string }) => {
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    }

    const httpBody = {

      "old_email": current_email,
      "new_email": new_email

    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  contactForm = (data: any) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const httpBody = {
      "data": {
        "name": data.name,
        "email": data.email,
        "reason": data.reason,
        "message": data.message,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    this.contactFormApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editUserProfile = (data: any) => {
    let token = localStorage.getItem('token');
    const headers = {
      "token": token
    };

    const httpBody = new FormData();
    for (let key in data) {
      httpBody.append(key, data[key])
    }
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editUserProfileApiEnd
    );

    this.editUserProfileApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudyIndexDetail = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudyApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getStudyIndexApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFilteredStudies = () => {
    return this.state.studyData?.filter((item: {
      attributes: any; study_name: string;
    }) =>
      item.attributes.study_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
    );
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleItemClick = (selectedStudy: any) => {
    this.setState((prevState) => ({
      selectedStudy: prevState.selectedStudy === selectedStudy ? null : selectedStudy.id,
      selectedStudyName: selectedStudy.attributes.study_name,
      studyDrawerOpen: false
    }));
    setStorageData("studyName", JSON.stringify(selectedStudy));
    setStorageData("studyId", JSON.stringify(selectedStudy.id));
    this.props.navigation.navigate('Patient'); 
    window.location.reload();
  };

  handleStudyDrawerOpen = () => {
    this.setState({ studyDrawerOpen: !this.state.studyDrawerOpen });
  };
  handleClickShowOldPassword = () => {
    this.setState((prevState) => ({
      showOldPassword: !prevState.showOldPassword
    }))
  }
  handleClickShowNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword
    }))
  }
  handleClickShowNewConfirmPassword = () => {
    this.setState((prevState) => ({
      showNewConfirmPassword: !prevState.showNewConfirmPassword
    }))
  }

  onUpload = (file: File) => {
    this.setState({ profileImageError: '' });

    const extensionArray = file.name.split('.');
    const extension = extensionArray.pop();

    if (extension) {
      const lowerCaseExtension = extension.toLowerCase();

      if (lowerCaseExtension !== 'jpg' && lowerCaseExtension !== 'jpeg') {
        this.setState({ profileImageError: 'Please upload a valid image file as only JPEG and JPG files are allowed' });
        return
      }

      this.editUserProfile({ profile_image: file });
    } else {
      this.setState({ profileImageError: 'Please upload a valid image file as only JPEG and JPG files are allowed' });
    }
  };
  // Customizable Area End
}
