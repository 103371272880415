import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const CustomPagination = ({ data, itemsPerPage, edit }: any) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, data.length);
    edit(data.slice(startIndex, endIndex));

  }, [currentPage, data, itemsPerPage, edit]);

  const handleClick = (pageNumber: any) => {
    window.scrollTo(0, 0)
    if (pageNumber > currentPage) {
      setCurrentPage(pageNumber);
    } else if (pageNumber < currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      window.scrollTo(0, 0)
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    if (currentPage < totalPages) {
      window.scrollTo(0, 0)
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Generate pagination buttons
  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(
      <button
        key={i}
        onClick={() => handleClick(i)}
        disabled={i === currentPage}
        style={{ backgroundColor: i === currentPage ? "#A046FB" : "white" }}
      >
        <Typography
          style={{
            color: i === currentPage ? "white" : "#A046FB",
            padding: '10px',
          }}
        >
          {i}
        </Typography>
      </button>
    );
  }

  return (
    <div>
      <div>
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          style={{
            backgroundColor: 'white',
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: '5px',
          }}
        >
          <Typography
            style={{
              color: "#A046FB",
              padding: '10px',
            }}
          >
            {`<`}
          </Typography>
        </button>
        {paginationButtons}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          style={{
            backgroundColor: 'white',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
          }}
        >
          <Typography
            style={{
              color: "#A046FB",
              padding: '10px',
            }}
          >
            {`>`}
          </Typography>
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
