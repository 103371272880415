import { Grid, Checkbox, Typography, createTheme, FormControlLabel, Avatar } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { tick } from '../../blocks/appointmentmanagement/src/assets';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

function CustomDropDownWithSearch({ options, onSelect, SelectedPatients, edit, disableflag }: any) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [checked, setChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const getInitials = (name: any) => {
        if (name) {
            const words = name.split(' ');
            const initials = words.map((word: string) => word.charAt(0).toUpperCase());
            return initials.join('');
        } else {
            return "";
        }
    };

    const handleOptionClick = (option: any) => {
        setSelectedOption(option);
        onSelect(option);
    };

    const handleCheckboxChange = (option: any) => {
        if (SelectedPatients) {
            const foundObject1 = SelectedPatients.find((obj1: { id: any; }) => obj1.id === option.id);
            if (foundObject1) {
                SelectedPatients = SelectedPatients.filter((item: any) => item !== option);
            } else {
                SelectedPatients = [...SelectedPatients, option];
                setSelectedItems(SelectedPatients);
            }
            edit(SelectedPatients);
        }
    };

    const handleCheckedValue = (option: any) => {
        return SelectedPatients.includes(option);
    };

    const filteredOptions = options?.filter((option: any) =>
        option.user_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ position: 'relative', width: '100%' }} ref={dropdownRef}>
            <div
                style={{
                    display: 'flex',
                    padding: '10px',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderRadius: '5px',
                    justifyContent: 'space-between',
                }}
                onClick={() => disableflag ? console.log(disableflag) : setIsOpen(!isOpen)}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={require("./profile.svg")} style={{ width: '20px', height: '20px', flexShrink: 0, marginRight: '5px' }} />
                    <span style={selectedOption ? { color: '#454545', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' } : { color: 'var(--Grey-Base, #939393)', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>{selectedOption || 'Add Participants'}</span>
                </div>
                <img src={require("./arrow-down.png")} />
            </div>
            {isOpen && (
                <div style={{
                    position: 'absolute', top: '100%', left: '0', width: '100%', backgroundColor: '#fff',
                    maxHeight: '200px', // Set max height to enable scrolling
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '5px', borderTop: 'none', zIndex: 100,
                    marginBottom: '20px'
                }}>
                    <input
                        type="text"
                        placeholder="Search User"
                        style={{
                            width: '95%', padding: '10px',
                            margin: '10px', borderRadius: '4px',
                            border: '1px solid #E6E6E6',
                            background: 'var(--White, #FFF)'
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    
                    {filteredOptions.map((option: any, index: any) => (
                        <Grid container spacing={3} alignItems="center" key={index}>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    label=''
                                    control={
                                        <Checkbox style={{
                                            borderRadius: '6.667px',
                                            border: '1.667px solid #E6E6E6',
                                            background: 'var(--White, #FFF)', marginLeft: '20px'
                                        }} name='check'
                                            value={checked}
                                            icon={<span style={{
                                                width: '4px',
                                                height: '4px',
                                                flexShrink: 0,
                                            }} />} checkedIcon={<img src={tick} />} />}
                                    checked={handleCheckedValue(option)}
                                    onChange={() => handleCheckboxChange(option)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Avatar style={{
                                    width: theme.spacing(7),
                                    height: theme.spacing(7),
                                    backgroundColor: '#F6EEFF', // Set the background color
                                    color: 'black',
                                    marginLeft: '10px'
                                    }}
                                    src={option.profile_image ? option.profile_image : ''}
                                >
                                </Avatar>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography style={{
                                    color: '#292929',
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                    fontFamily: 'Jost',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px', marginLeft: '10px'
                                }}>{option.full_name}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CustomDropDownWithSearch;
