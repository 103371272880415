import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  email: string;
  password: string;
  confirmPassword: any;
  passwordError: any;
  confirmPasswordError: any;
  open: boolean;
  otpScreen: boolean;
  emailScreen: boolean;
  emailErrorText: string;
  passwordSuccess: boolean;
  emailError: any;
  otp: any;
  validOtp: boolean;
  otpError: any;
  seconds: number;
  otpConfirm: string;
  isLoading: boolean;
  otpErrorText: string;
  openSnackbar: boolean;
  timerActive: boolean;
  showTerms: boolean;
  showPrivacy: boolean;
  showPasswordForget: boolean;
  showConfirmPasswordForget: boolean;
  openSnackbarInvalidToken: boolean;
  validateOtpMsg:string;
  maximumTimeOtp:number;
  otpTimerReach: boolean;
  otpCounterTimer: any;
  selectedLanguage: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countdown: any;
  countDownTimerReach: any;
  forgetEmailRegistrationAPIid: any;
  forgetEmailOTPConfirmationAPIid: any;
  forgetPasswordRegistrationAPIid: any;
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword?: boolean;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      countryCode: Yup.number()
        .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      email: "",
      password: "",
      seconds: 60,
      open: false,
      otpScreen: false,
      emailScreen: true,
      emailErrorText: "",
      passwordSuccess: false,
      emailError: null,
      otpError: null,
      otp: null,
      validOtp: false,
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      otpConfirm: "",
      isLoading: false,
      otpErrorText: "",
      openSnackbar: false,
      timerActive: true,
      showTerms: false,
      showPrivacy: false,
      showPasswordForget: false,
      showConfirmPasswordForget: false,
      openSnackbarInvalidToken: false,
      validateOtpMsg: '',
      maximumTimeOtp: 0,
      otpTimerReach: false,
      otpCounterTimer: '',
      selectedLanguage:'en'
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
    const selected_language = await getStorageData("selected_language")
    this.setState({ selectedLanguage: selected_language });
  }

  handleLanguageChangeRegistration = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedLanguage:event.target.value as string});
  };

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId === this.forgetEmailRegistrationAPIid && response) {
      this.handleForgetEmail(response)
    }
    if (apiRequestCallId === this.forgetEmailOTPConfirmationAPIid && response) {
      this.handleEmailOTP(response)
    }
    if (apiRequestCallId === this.forgetPasswordRegistrationAPIid && response) {
      this.handlePasswordRegistration(response, errorReponse)
    }
  }

  postForgetEmailRegistration = () => {

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token
    };

    let formDataCampaign = {
      "data": {
        "type": "email_account",
        "attributes":
        {
          "email": this.state.email,
          "role_name": "Site Coordinator"
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetEmailRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.forgetEmailRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postForgetEmailOTPConfirmation = async() => {
    this.setState({isLoading: true})
    const tokenData = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token
    };

    let formDataCampaignOtp = {
      "data": {
        "token": tokenData,
        "otp_code": this.state.otp
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetEmailOTPConfirmationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignOtp)
    );
    this.forgetEmailOTPConfirmationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postForgetPasswordRegistration = async () => {
    const tokenDataRegistration = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token
    };

    let formDataCampaignPassword = {
      "data": {
        "token": tokenDataRegistration,
        "new_password": this.state.password,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPasswordRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignPassword)
    );
    this.forgetPasswordRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentWillUnmount() {
    clearInterval(this.countdown);
    clearInterval(this.countDownTimerReach);
  }

  maximumOtpReachTimer = async () => {
    clearInterval(this.countDownTimerReach);
    this.countDownTimerReach = setInterval(() => {
      if (this.state.maximumTimeOtp > 0) {
        this.setState((prevState) => ({
          maximumTimeOtp: prevState.maximumTimeOtp - 1,
        }));
        if(this.state.otpTimerReach){
          this.setState({validOtp: true, validateOtpMsg: `Maximum OTP attempts reached. Please try again after 5 minutes - ${this.displayOtpReachTime()}`})
        }else{
          this.setState({otp: '',validateOtpMsg: '',validOtp: false,})
        }
      } else {
        clearInterval(this.countDownTimerReach);
        this.setState({otpTimerReach: false,maximumTimeOtp:0,})
      }
      if(this.state.maximumTimeOtp === 0){
        this.setState({validateOtpMsg: '',validOtp: false,})
      }
    }, 1000);
  }

  otpTimer = () => {
    clearInterval(this.countdown);
    this.countdown = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
      } else {
        if (this.state.timerActive) {
          this.handleResendOtp();
          clearInterval(this.countdown);
          this.setState({ timerActive: false });
        }
      }
    }, 1000);
  }

  handleForgetEmail = (responseJson: any) => {
    setStorageData("token",responseJson.data?.token)
    if (responseJson && responseJson.errors) {
      if (responseJson.errors[0].otp === "Account not found") {
        this.setState({ emailError: true, emailErrorText: "Please enter a valid email address", isLoading: false })
      }
    } else {
      this.setState({ otpConfirm: responseJson.data.attributes.pin, token: responseJson.meta.token, emailScreen: false, otpScreen: true, isLoading: false }, () => {
        this.otpTimer()
      })
    }
  }

  handleEmailOTP = (responseJson: any) => { 
        this.setState({ open: true, isLoading: false, }); 
      if(responseJson?.errors?.[0]?.otp ==='Invalid token'){
       this.setState({isLoading: false, openSnackbarInvalidToken: true,})
     }else if(responseJson?.meta?.message?.includes('Please enter')){
       this.setState({isLoading:false,validOtp:true, validateOtpMsg:responseJson?.meta?.message})
      }else if(responseJson?.meta?.message ==='Maximum OTP attempts reached. Please try again after 5 minutes.'){
       this.maximumOtpReachTimer();
       this.setState({isLoading:false,validOtp:true, otpTimerReach: true, maximumTimeOtp:300})
    } 
  } 

  handlePasswordRegistration(responseJson: any, errorReponse: any) {
    if (responseJson && !errorReponse) {
      this.handleSetPassword(responseJson);
    }
  }

  handleSetPassword = (responseJson: any) => {
    this.setState({ open: false, isLoading: false, otpScreen: false, passwordSuccess: true, });
  }

  handleClose = () => {
    this.setState({
      open: false, password: "", confirmPassword: "", confirmPasswordError: '', passwordError: '', showPasswordForget: false,
      showConfirmPasswordForget: false,
    });
  };

  handlePasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let confirmPasswordError = '';
    if (value !== this.state.confirmPassword) {
      confirmPasswordError = this.state.confirmPasswordError;
    }
    let passwordError = '';
    if (value !== '') {
      passwordError = strongPasswordRegex.test(value)
        ? ''
        : 'Password must contain 1 Uppercase, 1 Lowercase, 1 Special and 1 Numeric Characters!';
    }
    this.setState({
      confirmPasswordError,
      password: value,
      passwordError,
    });
  };

  handleConfirmPasswordChange = (value: any) => {
    this.setState({
      confirmPasswordError: value === '' || this.state.password === value ? '' : 'Password do not match',
      confirmPassword: value,
    });
  };

  submitPassWord = () => {
    if (this.state.password === "" && this.state.confirmPassword === "") {
      this.setState({ passwordError: "please enter password", confirmPasswordError: "please enter confirm password" })
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: "Password do not match" })
    } else if (this.state.confirmPassword === "") {
      this.setState({ confirmPasswordError: "please enter confirm password" })
    } else if (this.state.password === "") {
      this.setState({ passwordError: "please enter password" })
    }
    else if (this.state.confirmPasswordError === "" && this.state.passwordError === "") {
      this.postForgetPasswordRegistration();
      this.setState({
        isLoading: true
      })
    }
  }

  loginNavigationButton = () => {
    setStorageData("selected_language", this.state.selectedLanguage)
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleEmailChange = (value: string) => {
    const regex = configJSON.emailRegex
    if (value.trim() === '') {
      this.setState({ emailError: false, emailErrorText: '' });
    } else if (value.match(regex)) {
      this.setState({ emailError: false, emailErrorText: '' });
    } else {
      this.setState({
        emailError: true,
        emailErrorText: 'Please enter a valid email address',
      });
    }
  
    this.setState({ email: value });
  }

  handleEmailSubmit = () => {
    setStorageData("selected_language", this.state.selectedLanguage)
      this.postForgetEmailRegistration()
      this.setState({ isLoading: true })
      this.setState({isLoading: false,emailScreen:false,otpScreen:true, emailError: true, emailErrorText: 'Please enter a valid email address' })
  }

  displayTime() {
    const minutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  displayOtpReachTime() {
    const minutes = Math.floor(this.state.maximumTimeOtp / 60);
    const remainingSeconds = this.state.maximumTimeOtp % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  updatedOtpVal = (updatedOtp:string) =>{
    return updatedOtp?.length === 0?true:false
  }

  handleOtpChange = (e: any) => {
    if (e.key === 'Backspace') {
      const updatedOtp = this.state.otp.slice(0, -1);
      this.setState({ otp: updatedOtp, validOtp: this.updatedOtpVal(updatedOtp),validateOtpMsg:'Please enter a valid 6-digit OTP' });
    } else {
      const sanitizedValues = e.target.value.replace(/\D/g, '').slice(0, 6);
      if (sanitizedValues) {
        this.setState({ otp: sanitizedValues, validOtp: sanitizedValues.length === 0?true:false,validateOtpMsg:'Please enter a valid 6-digit OTP'  });
      } else {
        this.setState({ validOtp: false,validateOtpMsg: '', });
      }
    }
  };

  handleClickOpen = () => {
    setStorageData("selected_language", this.state.selectedLanguage)
    if (this.state.otp === null || this.state.otp === '' || this.state.otp?.length !== 6) {
      this.setState({ validOtp: true,validateOtpMsg:'Please enter a valid 6-digit OTP' })
    }
    else {
      this.setState({
        isLoading: false,
      },()=>{
        this.postForgetEmailOTPConfirmation()
      })
    }
  };

  handleResendOtp = () => {
    if (!this.state.timerActive) {
      this.postForgetEmailRegistration();
      this.setState({ openSnackbar: true, seconds: 60, timerActive: true, validOtp: false });
      this.otpTimer();
    }
  };

  handleSnackbarClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  handleSnackbarTokenClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbarInvalidToken: false });
  };

  handleEditEmailAddress = () => {
    clearInterval(this.countdown);
    clearInterval(this.countDownTimerReach);
    this.setState({
      timerActive: true, emailScreen: true, validOtp: false,validateOtpMsg:'',otpTimerReach:false, otpScreen: false, otp: null, seconds: 60,
    })
  }
  handleClosePrivacy = () => {
    this.setState({ showPrivacy: false })
  }
  handleTermsClick = () => {
    this.setState({ showTerms: true })
  };
  handleCloseTerms = () => {
    this.setState({ showTerms: false })
  }
  handlePrivacyClick = () => {
    this.setState({ showPrivacy: true })
  }
  handleClickShowPasswordForget = () => {
    this.setState((prevState) => ({
      showPasswordForget: !prevState.showPasswordForget
    }))
  }
  handleClickShowConfirmPasswordForget = () => {
    this.setState((prevState) => ({
      showConfirmPasswordForget: !prevState.showConfirmPasswordForget
    }))
  }
  // Customizable Area End
}
