import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  QuickBlox,
  QBUser,
  QBMediaParams,
  QBChatNewMessage,
  QBWebRTCSession,
} from "quickblox/quickblox";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  chatMessageVal: string;
  usersChatList: any[];
  getChatsList: any[];
  receiverIdChat: string;
  receiverChatObj: any;
  dialogObj: any;
  loginInfo: any;
  selectedFileType: any;
  selectedFileName: any;
  selectedFilePreview: any;
  chatsFilePreview: any;
  fileObj: any;
  filesResult: any;
  logoutFlag: boolean;
  filePreviewUrl: string;
  fileAttachmentArr: any[];
  accordionOpen: boolean;
  isSelectedUser: boolean;
  emailData: string;
  clickedSelectedUser: string;
  fileTypeUpload: string;
  selectedQuickBloxId: string;
  profileImagePic: string;
  patientDataChats: any[];
  isOpenAudioCall: boolean;
  isOpenMic: boolean;
  isUploadDocLoading: boolean;
  iOpenAcceptRejectPage: boolean;
  isShowTyping: boolean;
  isChatOkay: boolean;
  unreadAccordion: boolean;
  sessionState: any
  callingUser: {profile_image: string, name: string}
  patientsIds: number[]
  patientsProfile: any[]
  chatProfile: string | undefined
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  postMsgPhotoApiCallId:string = "";
  getUserProfileApiCallId: string = "";
  getTrailVisitScheduleCallID: string = "";
  chatInputHeight = "75px";
  bottomSpace = "16px";
  TestData = [
    {
      avatar:
        "https://pbs.twimg.com/profile_images/1498641868397191170/6qW2XkuI_400x400.png",
      name: "John Toretoasdasdas",
      lastMessage: "See you tmr",
      receivedAt: "12:30",
      unreadMessage: 2,
    },
    {
      avatar:
        "https://pbs.twimg.com/profile_images/1498641868397191170/6qW2XkuI_400x400.png",
      name: "John Toreto",
      lastMessage: "See you tmr",
      receivedAt: "12:30",
      unreadMessage: 2,
    },
    {
      avatar: "avatar 1",
      name: "John Toreto",
      lastMessage: "See you tmrr",
      receivedAt: "12:30",
      unreadMessage: 2,
    },
    {
      avatar: "avatar 1",
      name: "John Toreto",
      lastMessage: "See you tmrrrr",
      receivedAt: "12:30",
      unreadMessage: 2,
    },
    {
      avatar: "avatar 1",
      name: "John Toreto",
      lastMessage: "See you sdasd",
      receivedAt: "12:30",
      unreadMessage: 2,
    },
  ];
  Chats = [
    {
      user: 1,
      message: "hello,hellohellohelloheellohellohellohelloddddd",
    },
    {
      user: 2,
      message: "hello 1",
      name: "John Toreto",
    },
  ];

  QB = new QuickBlox();
  audioElement = new Audio()


  calleesIds = [139699068, 139699191];

  sessionType = this.QB.webrtc?.CallType?.AUDIO;
  session: any = {};

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      chatMessageVal: "",
      usersChatList: [],
      getChatsList: [],
      receiverIdChat: "",
      receiverChatObj: {},
      dialogObj: {},
      loginInfo: {},
      selectedFileType: "",
      selectedFileName: "",
      selectedFilePreview: "",
      chatsFilePreview: null,
      filesResult: {},
      fileObj: {},
      logoutFlag: false,
      filePreviewUrl: "",
      fileAttachmentArr: [],
      accordionOpen: false,
      isSelectedUser: false,
      emailData: "",
      clickedSelectedUser: "",
      fileTypeUpload: "",
      selectedQuickBloxId: "",
      profileImagePic: "",
      patientDataChats: [],
      isOpenAudioCall: false,
      isOpenMic: false,
      isUploadDocLoading: false,
      iOpenAcceptRejectPage: false,
      isShowTyping: false,
      isChatOkay: false,
      unreadAccordion: false,
      sessionState: {},
      callingUser: {profile_image: "", name: ""},
      patientsIds: [],
      patientsProfile: [],
      chatProfile: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.connectQuickBloxServer();
    this.getUserProfile();
    this.callTrailVisitScheduleApi();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {

    this.QB.webrtc.onCallListener = this.handleCallListner
    this.QB.webrtc.onStopCallListener = this.handleCallStopListner
    this.QB.webrtc.onRejectCallListener = this.handleCallRejectListner
    this.QB.webrtc.onRemoteStreamListener = this.handleCallStream
  }

  handleGetUserMedia = (error: any, stream: any) => {
    if (!error) {
      this.setState({ iOpenAcceptRejectPage: true });
      if (this.state.isOpenMic === false) {
        this.audioElement.srcObject = null;
        this.audioElement.pause()
      }
    }
  }

  handleCallRejectListner = (session: QBWebRTCSession, userId: QBUser['id'], extension: Record<string, any>) => { 
    this.setState({
      iOpenAcceptRejectPage: false,
      isOpenAudioCall: false,
    });
    this.state.sessionState.reject(extension);
   };

  handleCallStopListner = (session: QBWebRTCSession, userId: QBUser['id'], extension: Record<string, any>) => {
    this.setState({ isOpenAudioCall: false }, () => {
      this.state.sessionState.stop(extension);
    });
  };

  handleCallStream = (session: QBWebRTCSession, userId:  QBUser['id'], stream: any) => {
    this.audioElement.srcObject = stream;
    this.audioElement.play();
  }

  handleCallListner = (session: any, extension: any) => {
      const mediaParams: QBMediaParams = {
        audio: true,
        video: false,
      };

      this.setState({ sessionState: session, callingUser: extension })
      session?.getUserMedia(mediaParams, this.handleGetUserMedia);
  };

  connectQuickBloxServer = async () => {
    const storedCredentialsString: string | null | any = localStorage.getItem('quickBloxCredentials');
    const storedCredentials = JSON.parse(storedCredentialsString);

    const CONFIG = {
      // other settings
      webrtc: {
        disconnectTimeInterval: 86400,
      }
      // other settings
    };

    this.QB.init(storedCredentials?.appId, storedCredentials?.authKey, storedCredentials?.authSecret, storedCredentials?.accountKey, CONFIG);

    const userUniqnueId: any = localStorage.getItem("userUniqnueId");
    const userPassword = "Password@123";

    const paramsLogin = {
      login: userUniqnueId,
      password: userPassword,
    };
    this.setState({ accordionOpen: true });
    this.QB.createSession(paramsLogin, async (error: any, result: any) => {

      localStorage.setItem("userId", result.user_id);
      localStorage.setItem("chatToken", result.token);

      this.QB.login(paramsLogin, (error: any, result: any) => {
        this.setState({ loginInfo: result });
      })
      const Login = {
        userId: result.user_id,
        password: userPassword,
      };

      this.QB.chat.connect(Login, (err: any, result: any) => {
        this.getListOfDialog()
      })
    });
  };

  qbConnect = (error: any, result: any) => {
    if (error) {

      this.setState({ logoutFlag: true });
    }
  };

  handleLogout = () => {
    if (this.state.logoutFlag) {
      localStorage.clear();
      this.props.navigation.navigate("EmailAccountRegistration");
    }
  };

  handleCallToReceiver = (error: any) => {
    if (!error) {
      this.QB.webrtc.isExistLiveSessionExceptSessionID(this.session?.ID);
    }
  }

  handleSendAudioCall = (error: any, stream: any) => {
    if (!error) {
      const extension = {
        senderID: this.state.loginInfo?.id,
      };
      this.session?.call(extension, this.handleCallToReceiver);
    }
  }

  handleOpenAudioCall = () => {
    this.setState({ isOpenAudioCall: true }, () => {
      this.session = this.QB.webrtc.createNewSession(
        [Number(this.state.receiverIdChat)],
        this.QB.webrtc?.CallType?.AUDIO
      );

      const mediaParams: QBMediaParams = {
        audio: true,
        video: false,
        options: {
          muted: false,
          mirror: false,
        },
      };
      this.setState({ sessionState: this.session })
      this.session?.getUserMedia(mediaParams, this.handleSendAudioCall);
    });
  };

  connectToUserChat = () => {
    const userId = localStorage.getItem("userId");
    const chatToken = localStorage.getItem("chatToken");
    const userCredentials = {
      userId: Number(userId),
      password: String(chatToken),
    };
    this.QB.chat?.connect(userCredentials, this.qbConnect);
  };

  getQbMessages = (error: any, messages: any) => {

    if (messages) {
      this.setState({ isChatOkay: true })
      this.setState({ getChatsList: messages?.items });
    }
  };

  getAllMessages = () => {
    const paramsChatReceive = {
      chat_dialog_id: this.state.dialogObj?._id,
      sort_desc: "date_sent",
      limit: 200,
      skip:0,
    };
    this.QB.chat?.message?.list(paramsChatReceive, this.getQbMessages);
  };

  qbDialogCreate = (error: any, dialog: any) => {
    if (dialog) {
      this.setState({ dialogObj: dialog }, () => {
        if (this.QB.chat) {
          this.QB.chat.onMessageListener = this.onMessage.bind(this);
          this.getAllMessages();
          this.getListOfDialog();
        }
        this.getListOfDialog();
        this.setState({ logoutFlag: false });
      });
    }
  };

  createDialog = (user: any, flag: string, profile?: string) => {
    const quickBloxUserId = localStorage.getItem("quickBloxId")    
    const userIdNo: any[] = user?.occupants_ids?.filter(
      (dataId: any) => dataId !== Number(quickBloxUserId)
    );

    this.setState({
      receiverIdChat: userIdNo?.find((val: any) => val),
      receiverChatObj: user,
      clickedSelectedUser: flag,
      selectedQuickBloxId: user._id,
      chatProfile: profile
    });

    const paramsPrivateDilog = {
      type: user.type,
      occupants_ids: userIdNo?.find((val: any) => val),
    };

    this.QB?.chat?.dialog?.create(paramsPrivateDilog, this.qbDialogCreate);
  };

  onMessage(userId: QBUser["id"], message: any) {
    if (message.id) {
      this.setState({ isUploadDocLoading: false });
      this.getAllMessages();
      this.getListOfDialog()
    }
  }

  getListOfDialog = () => {
    const params = {
      limit: 100,
    };
  
    this.QB.chat.dialog?.list(params, (error: any, dialogs: any) => {
      if (dialogs) {
        const patientData: any = JSON.parse(localStorage.getItem('patientData') || '[]');
        const mainData = dialogs.items.map((dialog: any) => {
          this.setState({ patientsIds: [...this.state.patientsIds, dialog.user_id] })
          this.getPatientsProfile()
          const matchingPatient = patientData.find((patient: any) =>
            dialog.occupants_ids.includes(patient.user_data.quickblox_id)
          );
  
          if (matchingPatient) {
            return {
              ...dialog,
              full_name: matchingPatient.user_data.full_name,
            };
          }
          return dialog;
          })
          .filter((dialog: any) => dialog !== null);
        this.setState({ usersChatList:mainData });
        if(this.state.usersChatList.map((i) => i.unread_messages_count > 0)) {
        this.setState({ unreadAccordion: true });
        }
      }
    });
  };
  
  onUnreadChange = () => {
    this.setState({ unreadAccordion: !this.state.unreadAccordion });

  }
  
  handleSendMessage = async () => {
    const { fileObj, chatMessageVal, fileAttachmentArr, dialogObj, receiverIdChat, selectedFileName, fileTypeUpload } = this.state;

    const fileParams = {
        name: fileObj?.name,
        file: fileObj,
        type: fileObj?.type,
        size: fileObj?.size,
        public: false,
    };

    if (chatMessageVal.trim().length === 0 && (!fileObj || !fileObj.name)) {
        return;
    }

    let newFileAttachmentArr = [...fileAttachmentArr];

    if (fileObj && fileObj.name) {
        this.setState({ isUploadDocLoading: true });

        await new Promise((resolve, reject) => {
            this.QB.content?.createAndUpload(fileParams, (error: any, result: any) => {
                if (!error) {
                    this.setState({ filesResult: result });
                    newFileAttachmentArr = [...newFileAttachmentArr, { id: result?.uid, type: fileTypeUpload }];
                    resolve(result);
                } else {
                    this.setState({ isUploadDocLoading: false, selectedFilePreview: "", fileObj: {} });
                    alert(JSON.parse(error.message).errors[0]);
                    reject(error);
                }
            });
        });
    }

    const message: QBChatNewMessage = {
        type: "chat",
        body: chatMessageVal.trim() || selectedFileName,
        extension: {
            save_to_history: 1,
            dialog_id: dialogObj._id,
            attachments: newFileAttachmentArr,
        },
        markable: 1,
    };

    (message as any).id = this.QB.chat?.send(Number(receiverIdChat), message);
    this.onMessage(Number(receiverIdChat), message);

    const payload = {
      message: "You have received message from site coordinator",
      title: "Message Received!",
      type: "privateChat",
      details:JSON.stringify({
        name: "site coordinator",
        profileImage: "",
        chatID: localStorage.getItem("quickBloxId"),
        isCurrentUserIdentityHide: false,
        isOpponentIdentityHide: false
      })
    }
    let pushParameters: any = {
      notification_type: "push",
      user: { ids: [this.state.receiverIdChat] },
      environment: "production",
      message: this.QB.pushnotifications.base64Encode(JSON.stringify(payload))
    };
    
    this.QB.pushnotifications.events.create(pushParameters, function() {});

    const fileUrl = fileObj ? this.QB.content.privateUrl(this.state.filesResult?.uid) : "";
    this.setState({
        chatMessageVal: "",
        fileAttachmentArr: [],
        isUploadDocLoading: false,
        selectedFilePreview: "",
        selectedFileType: "",
        filePreviewUrl: fileUrl || "",
    });
};



  handleKeyDown = (evnt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evnt.keyCode === 13 && this.state.chatMessageVal.length !== 0) {
      this.handleSendMessage();
    }
  };

  attachFile() {
    document.getElementById("fileInput")?.click();
  }

  handleLoadFile = (e: any) => {
    this.setState({ isUploadDocLoading: false })
    if (this.state.selectedFileType?.includes("image")) {
      this.setState({ fileTypeUpload: "image" });
    } else if (this.state.selectedFileType?.includes("pdf")) {
      this.setState({ fileTypeUpload: "pdf" });
    } else if (this.state.selectedFileType?.includes("video")) {
      this.setState({ fileTypeUpload: "video" });
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const fileType = file.type;
      this.setState({
        isUploadDocLoading: true,
        selectedFileType: fileType,
        selectedFileName: file?.name,
      });
      const previewUrl = URL.createObjectURL(file);
      this.setState({ fileObj: file });
      const reader = new FileReader();
      reader.onload = this.handleLoadFile;
      reader.readAsDataURL(file);
      this.setState({ selectedFilePreview: previewUrl });
      
    }
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatView");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApisEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    this.handlePatientMessageData(message);
    if(
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.postMsgPhotoApiCallId &&
      responseJson
    ) {
      this.setState({ patientsProfile: responseJson.data})
    }
    if(
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getUserProfileApiCallId &&
      responseJson
    ) {
      this.handleGetProfileDataMessageCall(message, responseJson);
    }
    this.callResponceOfTrailVisitSchedule(message, responseJson);
  }

  handleGetProfileDataMessageCall = (message: any, responseJson: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUserProfileApiCallId &&
      responseJson
    ) {
      this.setState({
        profileImagePic: responseJson?.data?.attributes?.profile_image,
      });
    }
  };

  callResponceOfTrailVisitSchedule = (message: any, data1: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getTrailVisitScheduleCallID && data1) {

      this.setState({ patientDataChats: data1 });
    }
  };

  handleGetPatientData = async (message: Message) => {
    const patientData = await message.getData(
      getName(MessageEnum.PatientMessageData)
    );

    this.setState({
      accordionOpen: patientData?.isOpen,
      isSelectedUser: patientData?.isSelectedUser,
      emailData: patientData?.email,
    });
  };

  handlePatientMessageData = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage)) {
      if (message.getData(getName(MessageEnum.PatientMessageData))) {
        this.handleGetPatientData(message);
      }
    }
  };

  handleChatMessageChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = evnt.target.value;
      this.setState({ chatMessageVal: newValue });
  }

  handleChangeAccordionOpen = (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    this.setState({ accordionOpen: newExpanded ? true : false });
  };

  getPatientsProfile = async () => {
    let token = localStorage.getItem("token");
      const header = {
        token: token,
      };

      const formData = new FormData();
      formData.append("quickblox_ids", this.state.patientsIds as any);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postMsgPhotoApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postMsgPhoto
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  getUserProfile = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAccountApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callTrailVisitScheduleApi = async () => {
    const studyId = localStorage.getItem("studyId");

    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVisitScheduleAPiEndPoint}/${JSON.parse(String(studyId))}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getTrailVisitScheduleCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRespMicOff = (error: any, stream: any) => {
    if (!error) {
      this.audioElement.srcObject = null;
      this.audioElement.pause();
    }
  }
  handleMicOff = () => {
    this.setState({ isOpenMic: false });

    const mediaParams: QBMediaParams = {
      audio: true,
      video: true,
      options: {
        muted: true,
        mirror: false,
      },
    };
    this.session?.getUserMedia(mediaParams, this.handleRespMicOff);
  };

  handleRespMicOn = (error: any, stream: any) => {
    if (!error) {
      if (this.state.isOpenMic === true) {
        this.audioElement.srcObject = stream;
        this.audioElement.play();
      }
    }
  }

  handleMicOn = () => {
    this.setState({ isOpenMic: true });
    const mediaParams: QBMediaParams = {
      audio: true,
      video: true,
      options: {
        muted: false,
        mirror: false,
      },
    };
    this.session?.getUserMedia(mediaParams, this.handleRespMicOn);
  };

  handleLeaveCall = () => {
    this.setState({ isOpenAudioCall: false }, () => {
      const extension = {};
      this.state.sessionState.stop(extension);
    });
  };

  handleAcceptCallListener = (
    session: any,
    userId: any,
    extension: any
  ) => {
    const mediaParams: QBMediaParams = {
      audio: true,
      video: false,
      options: {
        muted: true,
        mirror: false,
      },
      elemId: "localVideoElem",
    };
    session?.getUserMedia(mediaParams, (error: any, stream: any) => {
      if (!error) {
        session?.accept(extension);
        session?.attachMediaStream("localVideoElem", stream);
      }
    });
  };

  handleAcceptCall = () => {
    this.setState({ iOpenAcceptRejectPage: false, isOpenAudioCall: true });
    this.state.sessionState.accept({})
    this.QB.webrtc.onAcceptCallListener = this.handleAcceptCallListener
  };

  handleRejectCallListner = (
    session: any,
    userId: any,
    extension: any
  ) => {
    const mediaParams: QBMediaParams = {
      audio: true,
      video: true,
      options: {
        muted: true,
        mirror: false,
      },
    };
    session?.getUserMedia(mediaParams, (error: any, stream: any) => {
      if (!error) {
        session?.reject(extension);

        session?.attachMediaStream("localVideoElem", stream);

        this.audioElement.pause();
      }
    });
  };

  handleRejectCall = () => {
    this.setState({
      iOpenAcceptRejectPage: false,
      isOpenAudioCall: false,
    });


    this.QB.webrtc.onRejectCallListener = this.handleRejectCallListner
  };

  // Customizable Area End
}
