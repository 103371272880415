import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Breadcrumbs,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { imgLeftBackArrow } from "./assets";
import { CommunityList, ChatMessage } from "./GroupsController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GroupsController, {
  Props,
  configJSON,
} from "./GroupsController";
import CustomDropDownWithSearch from "../../../components/src/CustomDropDownWithSearch";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCommunityWithImage = (val?: CommunityList) => {
    return <>
      <Box display={'flex'}>
        <img src={val?.attributes.image_url} style={webStyle.imgLogo} />
        <Box display={'flex'} flexDirection={'column'} justifyContent={"center"}>
          <Typography style={{ fontWeight: 400, fontSize: "1.15rem" }}>{val?.attributes.name}</Typography>
          <Box display={'flex'} alignItems={"center"} pt={"10px"}>
            <Typography style={{ fontWeight: 300, color: "grey", paddingRight: "10px" }}>{val?.attributes.region}</Typography>
            <FiberManualRecordIcon style={{ color: "grey", paddingRight: "10px", fontSize: "12px" }} />
            <Typography style={{ fontWeight: 300, color: "grey" }}>{val?.attributes.member_count}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  }

  formatDateCommunity = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    const startTime = new Date(date);
    startTime.setHours(10, 0, 0, 0);
    const endTime = new Date(date);
    endTime.setHours(13, 0, 0, 0);

    const formattedTime = (date >= startTime && date < endTime) ? date.toLocaleTimeString('en-US', options) : "01:00 PM";

    return formattedTime;
  };

  renderCommunityPage = () => {
    if (!this.state.isCommunityListShow) {
      console.log('CustomDropDownWithSearch', CustomDropDownWithSearch);

      return <Box>
        <Typography style={webStyle.titleStyle2}>Community Names</Typography>
        {
          this.state.communityForumList?.length > 0 ?
            this.state.communityForumList?.map((item: CommunityList) => {
              return <Box display={'flex'} flexDirection={'column'} mt={"20px"}>
                <Box>
                  <Box display={'flex'} justifyContent={"space-between"} py={'.3rem'} alignItems={"center"}>
                    {this.renderCommunityWithImage(item)}
                    <ArrowForwardIcon style={webStyle.arrowForwardIcon} data-testid="showDetailsPage" onClick={this.handleShowDetailsPage.bind(this, item)} />
                  </Box>
                  <hr style={webStyle.dividerRule} />
                </Box>
              </Box>
            })
            : <Typography>No Record Found</Typography>
        }
      </Box>
    } else {
      return <>
        <Grid container style={{ display: 'flex', justifyContent: "start", alignItems: 'center', gap: '10px' }}>
          <Grid>
            <Button style={webStyle.buttonStyle} startIcon={< ArrowBackIcon style={{ fontSize: "21px", marginLeft: "4px", fontWeight: "bolder" }} />} data-testid="backBtn" onClick={this.handleGoToCommunity}>
              Back
            </Button>
          </Grid>
          <Grid>
          <Breadcrumbs style={webStyle.breadCrumbStyle} separator={<ArrowRightIcon style={{ color: "#A776FB", fontSize: "25px" }} />} aria-label="breadcrumb">
            <span data-testid="goToDashboard" color="inherit" style={{ color: "grey", cursor: "pointer" }} onClick={this.handleGoToDashboard}>
              Dashboard
            </span>
            <span data-testid="goToCommunity" onClick={this.handleGoToCommunity} style={{ color: "grey", cursor: "pointer" }} color="inherit">
              Community
            </span>
            <span color="inherit" style={{ color: "grey", cursor: "default" }}>
              {this.state.communityForumDetails.attributes.name}
            </span>
          </Breadcrumbs>
          </Grid>
        </Grid>
        <Box ml={"27px"} mt={"30px"}>
          {this.renderCommunityWithImage(this.state.communityForumDetails)}
          <hr style={webStyle.dividerRule} />
          <Box>
            <Typography style={{color: "#A776FB",fontSize: "1.2rem",fontFamily: "Jost",fontWeight: 700,textAlign: "left" }}>Announcements</Typography>
            <hr style={webStyle.lineRuleDetailStyle} />
          </Box>
          {this.state.announcementChats?.data?.attributes?.messages?.length > 0 ? this.state.announcementChats?.data?.attributes?.messages?.map((val: ChatMessage) => {
            return <Box>
              <Box display={'flex'} alignItems={"center"} mt={'1.5rem'}>
                <Box width={"15rem"}>
                  <Typography style={{ fontWeight: 600 }}>Date:</Typography>
                  <p style={webStyle.annoucementColor}>{this.formatDateCommunity(val?.attributes?.updated_at)}</p>
                </Box>
                <Box width={"15rem"}>
                  <Typography style={{ fontWeight: 600 }}>Time:</Typography>
                  <p style={webStyle.annoucementColor}>{this.formatTime(val?.attributes?.created_at)} to {this.formatTime(val?.attributes?.updated_at)}</p>
                </Box>
              </Box>
              <p style={webStyle.annoucementColor}>{val?.attributes?.message}</p>
              <hr style={webStyle.dividerRule} />
            </Box>
          }) : <Box>
            <Typography style={{ paddingTop: '2rem', textAlign: "center", ...webStyle.annoucementColor }}>No Announcments Found</Typography>
            <hr style={webStyle.dividerRule} />
          </Box>
          }

        </Box>
      </>
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
        />
        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} selected="community" />
          <Box padding={'30px'} width={"100%"}>
            <Typography style={webStyle.titleStyle}>Community</Typography>
            <p style={webStyle.paraGraph}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            {this.renderCommunityPage()}
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle:{
    border:"2px solid #a046fb",
    borderRadius:"9px",
    padding:"5px",
    textTransform:"none" as "none",
    fontWeight:700
  },
  titleStyle: {
    fontWeight: 700 as 700,
    fontSize: '40px',
    fontFamily: "Jost",
    lineHeight: "50px",
    textAlign: "left" as 'left',
    color:'#1F3650'
  },
  paraGraph: {
    fontWeight: 400 as 400,
    fontSize: '14px',
    lineHeight: '26px',
    fontFamily: "Jost",
    textAlign: "left" as "left"
  },
  titleStyle2: {
    fontWeight: 500 as 500,
    fontSize: "32px",
    fontFamily: "Jost",
    lineHeight: "50px",
    textAlign: "left" as "left"
  },
  imgLogo: {
    width: '55px',
    height: "55px",
    border: ".1px solid white",
    borderRadius: "100%",
    marginRight: "20px"
  },
  arrowForwardIcon: {
    color: "#A776FB",
    paddingRight: '30px',
    cursor: "pointer",
    paddingTop: "30px"
  },
  dividerRule: {
    width: "100%",
    color: "#80808033",
    marginTop: "25px"
  },
  lineRuleDetailStyle: {
    color: 'rgb(167, 118, 251)',
    width: '92.5px',
    padding: '0px',
    margin: '0px',
    height: '2.2px',
    backgroundColor: 'rgb(167, 118, 251)',
    marginTop: '5px',
    border: '.1px solid rgb(167, 118, 251)',
    borderRadius: '50px'
  },
  breadCrumbStyle: {
    width: '100%',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#F1F1F1DE',
    paddingLeft: '15px',
    borderRadius: '8px',
    fontFamily: "Jost",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left" as "left"
  },
  annoucementColor: {
    color: 'gray',
    fontFamily: 'Jost',
    fontSize: "18px",
  }
};
// Customizable Area End
