import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, Hidden, InputAdornment, Paper, TextField, Typography, styled,CircularProgress, IconButton, Select, MenuItem } from "@material-ui/core";
import { DropdownLogo, LanguageLogo, backDrop, boxheaderLogo, emailLogo, headerLogo, lockLogo, sideLogo } from "../../email-account-registration/src/assets";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import { createTheme } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ModalStyle = styled(Box)({

  "& .form-logo": {
    textAlign: "center",

  },

  "& .paper-container": {
    backgroundImage: `url(${backDrop})`,
    height: "100vh",
    overflowX: 'hidden',
  },

  "& .header": {
    backgroundColor: "#fff",
    padding: '30px 0',
  },
  "& .paper": {
    textAlign: 'center',
    padding: 2,
  },

  "& .textPara": {
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    marginLeft: '20px',
    marginBottom: '100px',
    color: "#0F2220",
    fontFamily: "Jost",
    fontSize: "30px",


  },
  "& .box": {
    padding: 30,
    borderRadius: "10px",
    border: "2px solid #A046FB",
    alignItems: 'center',
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
  },

  "& .form-tnc": {
    textAlign: 'center',

  },
  "& .form-termsgroup": {
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
    color: "#2B2B2B",

  },
  "& .form-terms": {
    color: "#A046FB",
    cursor: 'pointer',
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
  },

  "& .languageTxt": {
    marginLeft: '4px',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#000",
  },
  "& .englishTxt": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: '4px',
    color: "#A046FB",

  },

  "& .welcome": {
    fontFamily: "Inter",
    fontStyle: "normal",

    fontSize: "20px",
    fontWeight: 600,

    color: "#535655",

  },

  "& .loginEmail": {
    height: '49px',
    marginTop: '20px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Jost",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
  },
  "& .loginPswd": {
    height: '49px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Jost",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
  },

  "& .forgetPswd": {
    color: "#A046FB",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    textAlign: 'end',
    cursor: 'pointer',
  },

  "& .btn-grid": {
    textAlign: "center",

  },

  "& .loginBtn": {
    textTransform: 'capitalize',
    background: "#A046FB",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    height: '48px',
    width: '90%',
    borderRadius: "2px",
    border: "2px solid #A046FB",
    color: '#fff',
    fontFamily: 'Inter',
  }

})

const Logo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Box className="form-logo">
          <img src={boxheaderLogo} alt="boxheaderLogo" />
        </Box>
      </Grid>
    </>

  )
}



// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      // Customizable Area Start
     const Terms = () => {
        const {showTerms, showPrivacy} = this.state;
        const signInMessage: { [key: string]: string } = {
          en: "By signing in, you're agreeing to Medical Trail App's",
          fr: "En vous connectant, vous acceptez",
          de: "Durch die Anmeldung stimmen Sie den Allgemeinen",
          es: "Al iniciar sesión, acepta los"
        };
    
        const termsMessage: { [key: string]: string } = {
          en: "Terms and Conditions",
          fr: "les conditions",
          de: "Geschäftsbedingungen",
          es: "Términos y condiciones"
        };
    
        const generalAndMessage: { [key: string]: string } = {
          en: "and",
          fr: "générales et la",
          de: "und der",
          es: "y la"
        };
    
        const privacyMessage: { [key: string]: string } = {
          en: "Privacy Policy",
          fr: "Politique de Confidentialité",
          de: "Datenschutzrichtlinie",
          es: "Política de privacidad"
        };
    
        const appMessage: { [key: string]: string } = {
          en: "of Medical Trail App",
          fr: "de Medical Trail App",
          de: "der Medical Trail App zu",
          es: "de la aplicación Medical Trail"
        };
        return (
          <>
            <Grid item xs={12}>
              <Box className="form-tnc">
                <Typography className="form-termsgroup">
                {signInMessage[this.state.selectedLanguage] || signInMessage['en']}
                  <Typography className="form-terms"><Typography className="form-terms" component={'span'} id="handleClickBtn" onClick={() => this.handleTermsClick()} >
                  {termsMessage[this.state.selectedLanguage] || termsMessage['en']}
                  </Typography>
                    <Typography className="form-termsgroup" component={"span"}>
                    {generalAndMessage[this.state.selectedLanguage] || generalAndMessage['en']}
                     </Typography>
                    <Typography className="form-terms" component={'span'} id="handleClickPrivacy" onClick={() => this.handlePrivacyClick()}> 
                    {privacyMessage[this.state.selectedLanguage] || privacyMessage['en']}
                    </Typography>
                    <Typography className="form-termsgroup" component={'span'} > 
                    {appMessage[this.state.selectedLanguage] || appMessage['en']}
                    </Typography></Typography>
                </Typography>
                {showPrivacy && <PrivacyPolicy navigation={undefined} id={""} handleClosePrivacy={() => this.handleClosePrivacy()}/>}
                {showTerms && <TermsConditions navigation={undefined} id={""} handleCloseTerms={() =>this.handleCloseTerms()} />}
              </Box>
            </Grid>
          </>
      
        )
      }
    const { emailError, passwordError,email,passwordErrorText } = this.state
      // Customizable Area Start
      const scientificTrialText: { [key: string]: string } = {
        en: "Scientific trials testing vaccines for effectiveness and safety",
        fr: "Essais scientifiques testant l'efficacité et la sécurité des vaccins",
        de: "Wissenschaftliche Studien prüfen Impfstoffe auf Wirksamkeit und Sicherheit",
        es: "Ensayos científicos que prueban la eficacia y seguridad de las vacunas"
      };
  
      const welcomeText: { [key: string]: string } = {
        en: "Welcome to Patientist",
        fr: "Bienvenue chez Patientiste",
        de: "Das Passwort stimmt nicht überein",
        es: "Bienvenido a Paciente"
      };
  
      const emailPlaceholder: { [key: string]: string } = {
        en: "Email Address",
        fr: "Adresse e-mail",
        de: "E-Mail-Adresse",
        es: "Dirección de correo electrónico"
      };
  
      const passwordPlaceholder: { [key: string]: string } = {
        en: "Enter New Password",
        fr: "Entrer le mot de passe",
        de: "Passwort eingeben",
        es: "Introducir la contraseña"
      };
  
      const forgetPasswordText: { [key: string]: string } = {
        en: "Forgot Password?",
        fr: "Mot de passe oublié?",
        de: "Passwort vergessen?",
        es: "¿Has olvidado tu contraseña?"
      };
  
      const loginButtonText: { [key: string]: string } = {
        en: "Login",
        fr: "Se connecter",
        de: "Anmeldung",
        es: "Iniciar sesión con nueva contraseña"
      };

      const languageLabelMessages: { [key: string]: string } = {
        en: "Language:",
        fr: "Langue:",
        de: "Sprache:",
        es: "Idioma:"
      };
    return (
      // Required for all blocks
      // Customizable Area start
      <React.Fragment>
        <ModalStyle>
          <Paper elevation={0} className="paper-container">
            <Box style={{ position: 'sticky', left: 0, right: 0, top: 0, }}>
              <header className="header">
                <Container maxWidth="lg">
                  <img src={headerLogo} alt="headerlogo" style={{  height: "56px", maxWidth: "330px", width: "100%"}}/>
                </Container>
              </header>
            </Box>
            <Container maxWidth="lg" style={{marginTop:'60px'}}>

              <Grid container className="main" alignItems="center" >
                <Hidden smDown>
                  <Grid item xs={12} md={6}>
                    <Typography className="textPara" > 
                    {scientificTrialText[this.state.selectedLanguage] || scientificTrialText['en']}
                    </Typography>
                    <img src={sideLogo} alt="sideLogo" />
                  </Grid>
                </Hidden>

                {/* //---- Start Login Credential UI */}

                <Grid container item xs={12} md={6} justifyContent="center" >
                  <Grid item xs={10} >
                    <Box className="box" >
                      <Grid spacing={3} container  >
                       <Logo/>
                        <Grid item xs={12}>
                          <Box className="form-logo">
                            <Typography className="welcome" >
                            {welcomeText[this.state.selectedLanguage] || welcomeText['en']}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            placeholder={emailPlaceholder[this.state.selectedLanguage] || emailPlaceholder['en']}
                            onChange={(e) => this.handleEmailChange(e.target.value)}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={emailLogo} />
                                </InputAdornment>
                              ),
                              style: { height: 49 },
                              className: 'loginEmail'
                            }}
                            id="email"
                            error={emailError}
                            value={email}
                            helperText={!emailError ? '' : this.state.emailErrorText}

                          />

                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type={this.state.showPasswordLogin ? 'text' : 'password'}                                
                            id="password"
                            variant="outlined"
                            placeholder={passwordPlaceholder[this.state.selectedLanguage] || passwordPlaceholder['en']}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={lockLogo} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.password.length > 0 ? (<IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleClickShowPasswordLogin()}
                                    >
                                        {this.state.showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>) : null}
                                </InputAdornment>
                            ),
                              className: 'loginPswd'
                            }}
                            onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                            value={this.state.password}
                            error={passwordError}
                            helperText={passwordError?passwordErrorText:""}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography className="forgetPswd" id="forgetPswd" onClick={() => this.handleForgetPassword()}>
                            {forgetPasswordText[this.state.selectedLanguage] || forgetPasswordText['en']}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="btn-grid">
                          <Button variant="outlined"
                            className="loginBtn"
                            id="loginBtn"
                            onClick={() => this.handleLoginSubmit()}
                          > {this.state.isLoader ? <CircularProgress size={30} style={{color:'#fff'}} /> : (loginButtonText[this.state.selectedLanguage] || loginButtonText['en']
                        )}</Button>                    
                        </Grid>
                       <Terms />
                      </Grid>
                    </Box>
                    <Box style={{ marginTop: '33px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={LanguageLogo} alt="LanguageLogo" style={{ verticalAlign: 'top' }} />
                      <Typography component={"span"} className="languageTxt">
                        {languageLabelMessages[this.state.selectedLanguage] || languageLabelMessages['en']}
                        <Select
                          className="englishTxt"
                          id="handleEnglish"
                          value={this.state.selectedLanguage}
                          onChange={this.handleLanguageChange}
                          style={{ marginLeft: '10px' }}
                          disableUnderline
                          IconComponent={ExpandMoreIcon}
                        >
                          <MenuItem value="en">English</MenuItem>
                          <MenuItem value="fr">French</MenuItem>
                          <MenuItem value="de">German</MenuItem>
                          <MenuItem value="es">Spanish</MenuItem>
                        </Select>
                      </Typography>
                    </Box>
    
                  </Grid>
                </Grid>

                {/* //---- End Login Credential UI */}
              </Grid>
            </Container>
          </Paper>
        </ModalStyle>
      </React.Fragment>
      // Customizable Area End
    );
  }
}
