Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'
exports.getAppointmentListAPiPutMethod = 'PUT'

exports.errorTitle = 'Error'
exports.getApiEndpoint="/bx_block/all"
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.getAllMileStoneEndPointNew="/bx_block_appointment_management/schedules/all_milestones?study_id="
exports.getAllMileStoneEndPoint = '/bx_block_appointment_management/schedules/fetch_milestones?study_id='
exports.addScheduleEndPoint = '/bx_block_appointment_management/schedules'
exports.getAllAppointment='/bx_block_appointment_management/schedules/upcoming_schedules?milestone_management_id='
exports.getScheduleDeleteApiEndPoint='/bx_block_appointment_management/schedules/'
exports.editScheduleApiEndPoint='/bx_block_appointment_management/schedules/update_schedule/'
exports.showScheduleApiEndPoint='/bx_block_appointment_management/schedules/show/'
exports.updateshowScheduleApiEndPoint='/bx_block_appointment_management/schedules/create_patient_schedule?schedule_id='
exports.calenderApiEndPoint='/bx_block_appointment_management/schedules/schedule_calendar?month='
exports.postSendNotification='/bx_block_appointment_management/schedules/send_notification'
// Customizable Area End
