import { BlockComponent } from "../../framework/src/BlockComponent";

export type TabValue =
  | "dashboard"
  | "patient"
  | "task_list"
  | "visit_schedule"
  | "send_notification"
  | "community";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selected?: TabValue
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selected: TabValue;
  showModal: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

class SidebarController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: "dashboard",
      showModal: false
    };
  }

 
  componentDidMount = async () => {
    this.setState({selected: this.props.selected || "dashboard"})
    return
  }

  selectTab = (key: TabValue) => {
    console.log("selected tab",key)
    this.setState({ selected: key });

    switch (key) {
      case "dashboard": {
        return this.props.navigation.navigate("Dashboard");
      }
      case "patient": {
        return this.props.navigation.navigate("Patient");
      }
      case "visit_schedule": {
        this.props.navigation.navigate("Appointments");
        return;
      }
      case "task_list": {
        this.props.navigation.navigate("TaskList");
        return;
      }
      case "send_notification": {
        this.props.navigation.navigate("Pushnotifications");
        return;
      }
      case "community": {
        this.props.navigation.navigate("CommunityForum");
        return;
      }
      // TODO: complete the switch case statement using the provided "key" variable

      default: {
        // open modal as default action
        return this.openModal();
      }
    }
  };

  openModal = () => {
    this.setState({showModal: true})
  }

  closeModal = () => {
    this.setState({showModal: false})
  }
}

export default SidebarController;
