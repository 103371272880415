Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "customisableuserprofiles2";
  exports.labelBodyText = "customisableuserprofiles2 Body";
  exports.getApiMethod = "GET";
  exports.postApiMethod = "POST";
  exports.patchApiMethod = "PATCH"
  exports.putApiMethod = "PUT";
  exports.deleteAllAppointmentsAPiMethod="DELETE";
  exports.apiContentType = "application/json";
  exports.formDataApiContentType = "multipart/form-data";
  exports.getUserAccountApiEndPoint = "profile/user_profiles";
  exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
  exports.getPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
  exports.getFaqsApiEndPoint = "bx_block_content_management/faqs"
  exports.getContactUsApiEndPoint = "bx_block_contact_us/contacts"
  exports.editUserProfileApiEnd = "bx_block_profile/profiles/update_profile"
  exports.changePasswordApiEndPoint = "bx_block_profile/new_password_set"
  exports.changeEmailApiEndPoint = "bx_block_profile/change_email"
  exports.getStudyApiEndPoint = "bx_block_catalogue/studies"
  exports.btnExampleTitle = "CLICK ME";
  exports.phoneNumberRegex = /^\+?[0-9]{1,3}\s?(\([0-9]+\)|[0-9]+){1,}$/;
  exports.nameRegex = /^[a-zA-Z\s]+$/;
  exports.emailRegex =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  exports.getAllNotificationApiEndPoint="bx_block_notifications/notifications"
  exports.getdeleteNotificationApiEndPoint="bx_block_notifications/notifications/delete_message"
  exports.getAllNotificationApiEndPoint="bx_block_notifications/notifications"
  exports.getAllNotificationMarkAsReadApiEndPoint= "bx_block_notifications/notifications/read_message"
  // Customizable Area End