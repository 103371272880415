export const emailLogo = require("../assets/emailLogo.svg");
export const idLogo = require("../assets/Id_Logo.svg");
export const designPattern = require("../assets/design_pattern.png");
export const designPattern2 = require("../assets/design_pattern_2.png");
export const imagePicture = require("../assets/image_picture.png");

export const down = require("../assets/arrow-down.png");
export const milestone = require("../assets/des.svg");
export const Milestone =  require("../assets/milestone.svg");
export const sideImage =  require("../assets/sideImage.svg");
export const progressSide =  require("../assets/progress.svg");

