import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleClosePrivacy?: any,
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    privacyPolicy: any,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPrivacyPolicyCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            privacyPolicy: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getPrivacyPolicy();
    }


    getPrivacyPolicy = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPrivacyPolicyCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPrivacyPolicyApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (
            responseJson &&
            apiRequestCallId === this.getPrivacyPolicyCallId
        ) {
            this.setState({
                privacyPolicy: responseJson.data.description,
            });
        }
        // Customizable Area End
    }
}
