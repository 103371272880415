import React from 'react'
import { Doughnut } from "react-chartjs-2"

interface IProps {
    data: any
}  

export const DoughnutChart: React.FC<IProps> = (props) => {
    const {data} = props

  return(
    <div>
      <div style={{
        width: 250,
        height: 250,
        margin: 0,
        padding: 0,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none'
      }}>
        <Doughnut options={{
          cutoutPercentage: 80,
          tooltips: {
            enabled: true
          },
          legend: {
            display: false
          }
        }} data={data} />
      </div>
      <div style={{display: 'flex', marginBottom: 32}}>
        {
          data.labels.map((item: any, index: any) => {
            console.log(index)
            return <div style={{display: 'flex', marginRight: 16, marginLeft: 16, alignItems: 'center'}}>
                <div style={{width: 10, height: 10, borderRadius: 5, marginRight: 8, background: data.datasets[0].backgroundColor[index]}}/>
                <span>{data.labels[index]}</span>
              </div>
          })
        }
      </div>
    </div>
  )
}