import React from "react";

// Customizable Area Start
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, styled } from "@material-ui/core";
// Customizable Area End

import PrivacyPolicyController, {
  Props,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { privacyPolicy } = this.state
    return (
        <>
          <Dialog
            fullWidth
            className="privacyContainer"
            onClose={() => this.props.handleClosePrivacy()}
            id="privacyPolicyBtn"
            scroll="body"
            open={true}
            maxWidth={'lg'}
            aria-labelledby="responsive-dialog-title" >
            <PrivacyDialogStyle>
              <DialogActions>
                <IconButton data-test-id="cancel-terms" onClick={() => this.props.handleClosePrivacy()}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                </IconButton>
              </DialogActions>
              <DialogTitle >
                <Typography className='privacyText'>Privacy Policy</Typography>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <div className="dialogContent" dangerouslySetInnerHTML={{ __html: privacyPolicy }}/>
                </Box>
              </DialogContent>
            </PrivacyDialogStyle>
          </Dialog>
        </>
      )
    // Customizable Area End
  }
}

// Customizable Area Start
const PrivacyDialogStyle = styled(Box)({
  "& .MuiDialogTitle-root": {
    padding: '0 40px'
  },
  "& .MuiDialogContent-root": {
    padding: '8px 40px'
  },
  " & .privacyText": {
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "28px",
    lineHeight: "38px",
    fontWeight: 700,
    fontStyle: "normal",
    color: "#000",
    fontFamily: "Jost",
  },
  "& .privacyContainer": {
    maxWidth: '1100px',
    width: '100%',
  },
  "& .dialogContent": {
    lineHeight: "26px",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#3D3C3C",
  },
  "& .dialogContentPara": {
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "26px",
    marginBottom: '10px',
    paddingTop: '20px',
    color: "#3D3C3C",
  },
})
// Customizable Area End
