import React, { useState } from "react";
import { Box, TextField, IconButton } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import { des } from "../../blocks/appointmentmanagement/src/assets";



const useStyles = makeStyles((theme) => ({

    //     editor: {
    //         padding: theme.spacing(2),

    //     },
    textField: {
        // width: '100%',
       // paddingLeft: '12px',
        paddingRight: '8px',
        textAlign: 'left', // Set default alignment here
        maxHeight: '150px',
        border: 'none',

    },

    '.MuiInput-underline:before': {
        borderBottom: 'none'
    },
    '.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid white'
    }
}));
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-root::before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: "none",
            position: "absolute",
            transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            pointerEvents: "none",
            //  border:'none',
            '& .MuiInputBase-underline::before': {

                position: "absolute",
                transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                pointerEvents: "none",
                border: 'none'
            }
        },
        '& .MuiInput-underline::after': {
            borderBottom: '2px solid red', // Change the style of the underline after pseudo-element
        },
        '& .MuiInput-underline::before': {
            border: 'none'
        },
        //   '& .MuiInput-underline::after':{
        //     border:'none'
        //           },
        '& .MuiInputBase-root::after': {
            border: 'none',
            //hover:''
            // borderBottom: '2px solid green', // Change the style of the underline after pseudo-element

        },

    },
})(TextField);


function CustomTextEditor({ handleEditorChange, onSelect }: any) {
    const classes = useStyles();
    const [bold, setBold] = useState(false);
    const [italic, setItalic] = useState(false);
    const [underline, setUnderline] = useState(false);
    const [alignment, setAlignment] = React.useState('left');

    const handleToggleBold = () => {
        setBold(!bold);
    };

    const handleToggleItalic = () => {
        setItalic(!italic);
    };

    const handleToggleUnderline = () => {
        setUnderline(!underline);
    };

    const handleAlignment = (align: any) => {

        setAlignment(align);
    };

    const handleChange = (event: any) => {
        handleEditorChange(event.target.value);
    };
    return (
        <Box style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            height: '25%',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'left',
            borderRadius: '5px',
            marginTop: '20px'
        }}>
            <div style={{ display: 'flex', }}>
                <IconButton onClick={handleToggleBold} color={bold ? "primary" : "default"}>
                    <FormatBoldIcon />
                </IconButton>
                <IconButton onClick={handleToggleItalic} color={italic ? "primary" : "default"}>
                    <FormatItalicIcon />
                </IconButton>
                <IconButton onClick={handleToggleUnderline} color={underline ? "primary" : "default"}>
                    <FormatUnderlinedIcon />
                </IconButton>
                <IconButton onClick={() => handleAlignment("left")} color={alignment === "left" ? "primary" : "default"}>
                    <FormatAlignLeftIcon />
                </IconButton>
                <IconButton onClick={() => handleAlignment("center")} color={alignment === "center" ? "primary" : "default"}>
                    <FormatAlignCenterIcon />
                </IconButton>
                <IconButton onClick={() => handleAlignment("right")} color={alignment === "right" ? "primary" : "default"}>
                    <FormatAlignRightIcon />
                </IconButton>
            </div>
            <div style={{ borderTop: '1px solid #ccc', width: '100%' }}></div>
            <div style={{ display: 'flex', margin: '10px' }}>
                <img src={des} style={{
                    width: "20px", height: "20px", marginRight: "5px",
                    padding: '2px', marginTop: '5px'
                }} />
                <CustomTextField
                    className={classes.textField}
                    multiline
                    rows={10}
                    value={onSelect}
                    placeholder="Description"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }} // Disable underline

                    inputProps={{

                        style: {
                            fontWeight: bold ? "bold" : "normal",
                            fontStyle: italic ? "italic" : "normal",
                            textDecoration: underline ? "underline" : "none",
                            textAlign: alignment === 'left' ? 'left' : alignment === 'right' ? 'right' : alignment === 'center' ? 'center' : 'left',
                        },

                    }}
                />
            </div>
        </Box>
    );
}

export default CustomTextEditor;
