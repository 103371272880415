import { BlockComponent } from "../../framework/src/BlockComponent";

export interface Props {
  tabs: {label: string, value: string | number }[];
  handleSelectTab: (value: string | number) => void;
  selected: string | number;
}

export interface S {}

export interface SS {
  id: string;
}

class GenericTabsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
}

export default GenericTabsController;