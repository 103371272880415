import React, { useState } from 'react'
import { Avatar, Box, Dialog, DialogContent, styled } from "@material-ui/core"
import { QuickBlox } from 'quickblox/quickblox';
import moment from 'moment';
import { closeButton } from './assets';

interface Props {
  receiverId: string,
  senderId: number,
  message: string
  senderInfo?: any,
  receiverInfo?: any,
  chatInfo: any;
  selectedFileType:string;
  selectedFileName:string;
  filePreviewUrl: string;
  QB:QuickBlox;
  index:number;
  filesResult: any;
  selectedFilePreview: any;
  showDate: boolean;
  chatProfile: string | undefined
}

const dateInfoChat = (chatInfo:any) => { 
  if(chatInfo?.created_at){
    const date = new Date(chatInfo?.created_at);

    const options:any = {
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime
  }
}


export const ChatItem: React.FC<Props> = (props) => {
  const[open, setOpen] = useState(false);
  const[previewUrl, setPreviewUrl] = useState("");
  const[previewType, setPreviewType] = useState("");
  let {message, receiverId, receiverInfo,chatInfo, showDate, selectedFileType,selectedFileName,filePreviewUrl, index, selectedFilePreview, QB,filesResult, chatProfile} = props
  const isReceived = receiverId === chatInfo?.recipient_id?1:2
  const user = isReceived===2
  

  const Message = user=== true? ReceivedMessage : SentMessage
  
  const handleAllPreviewTags = (val:any,fileUrl:any)=>{
    if(val?.type?.includes('image')){
    return <img src={fileUrl} alt="Image" style={{width:'200px', height:"150px",objectFit: 'fill',}} onClick={() => handleMediaPreview(val,fileUrl)}/>
  }else if(val?.type?.includes('pdf')){
    return (
      <div style={{ position: 'relative', width: '200px', height: '150px' }}>
      <embed
        src={fileUrl}
        type="application/pdf"
        style={{ width: '200px', height: '150px' }}
      />
      <div
        onClick={() => handleMediaPreview(val,fileUrl)}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '200px',
          height: '150px',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
      />
    </div>
    )
    }else if(val?.type?.includes('video')){
      return(
        <div style={{ position: 'relative', width: '200px', height: '150px' }}>
          <video controls style={{width:'200px', height:"150px"}}><source src={fileUrl} type="video/mp4" /></video>
          <div
          onClick={() => handleMediaPreview(val,fileUrl)}
          style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
      />
        </div>
      )
    }
  }
  const handleMediaPreview = (type: {id: string, type: string}, fileUrl: string) => {
    setOpen(true)
    setPreviewType(type.type)
    setPreviewUrl(fileUrl)
  }
  const handleClosePreview = () => {
    setOpen(false)
    setPreviewUrl("")
    setPreviewType("")
  }
  const handleCloseButton = () => {
    setOpen(false)
    setPreviewUrl("")
    setPreviewType("")
  }
  const filePreview = ()=>{
    if(chatInfo?.attachments?.length>0){
      return chatInfo?.attachments?.map((val:any)=>{
        const fileUrl=QB.content.privateUrl(val?.id);
        const MessageAttachment = val?.id && user? ReceivedMessageAttachment : SentMessageAttachment
        return <Box display={'flex'} flexDirection={"column"}>
          <Message onClick={() => handleMediaPreview(val, fileUrl)}>{handleAllPreviewTags(val,fileUrl)}</Message>
          <MessageAttachment>{val?.id ?message:''}</MessageAttachment>
      </Box>
      })
    }
  }
  const renderTimeReceived = () => {
    return(
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 4,}}>
          <Avatar style={{ marginRight: 8 }} alt="Remy Sharp" src={chatProfile} />    
          <span style={{color: 'black', marginRight: 4}}>{receiverInfo?.full_name || ""}</span>
          <span style={{color: 'lightGray'}}>{dateInfoChat(chatInfo)}</span>
        </div>
    )
  }

  const renderTimeSent = () => {
    return(
      <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', marginBottom: 4,}}>
        <span style={{color: 'lightGray', alignSelf: "flex-end",}}>{dateInfoChat(chatInfo)}</span>
      </div>
    )
  }

  const formatDate = (date: string) => {
    const messageDate = moment(date);
    const today = moment().startOf('day');
    if (messageDate.isSame(today, 'day')) {
      return 'Today';
    } else {
      return messageDate.format('DD/MM/YY');
    }
  };

  return(
    <>
      {index === 0 ? 
      <>
      {selectedFileType.includes("pdf") && (
        <embed
          src={selectedFilePreview}
          title="PDF preview"
          style={{ width: '200px', height: '300px', border: '1px solid black' }}
        />
      )}
        {selectedFileType.includes("image") && (
          <img src={selectedFilePreview} alt="" style={{ width: '200px', height: 'auto' }} />
        )}
        {selectedFileType.includes("video") && (
          <video controls style={{ width: '200px', height: 'auto' }}>
            <source src={selectedFilePreview} type={selectedFileType} />
          </video>
        )}
        </> : null}
      <MessageContainer received={user ? true: false}>
        <div>
          {user ? renderTimeReceived() : renderTimeSent()}
          <MessageBox received={user}>
            {filePreview()}
              {message!==''&&!filePreview()&&<Message>
                {message}
              </Message>}
          </MessageBox>
        </div>
      </MessageContainer>
      {showDate && (
        <TimeStampBox>
          <span style={{position: 'absolute', content: '', top: '10px', left: '0', width: '100%', height: '1px', background: 'lightgray', zIndex: 0}}></span>
          <span style={{ fontFamily: 'Jost', fontSize: '16px', fontWeight: 600, color: ' lightgray', position: 'relative', backgroundColor: '#fff', paddingLeft: '10px', paddingRight: '10px' }}>{formatDate(chatInfo?.created_at)}</span>
        </TimeStampBox>
      )}

      <Dialog open={open} onClose={handleClosePreview} maxWidth="lg" fullWidth>
          <div>
            <img src={closeButton} style={{width: '40px', float: 'right', cursor: 'pointer'}} onClick={handleCloseButton}/>
          </div>
        <DialogContent>
          {previewType.includes('image') && (
            <img
              src={previewUrl || ''}
              alt="Full Preview"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          {previewType.includes('pdf') && (
            <embed
              src={previewUrl || ''}
              type="application/pdf"
              style={{ width: '100%', height: '80vh', border: 'none' }}
            />
          )}
          {previewType.includes('video') && (
            <video controls style={{ width: '100%', height: 'auto' }}>
              <source src={previewUrl || ''} type="video/mp4" />
            </video>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}



const MessageBox = styled(Box)({
  minWidth: 0,
  wordWrap: 'break-word',
  flexDirection: 'column',
  WebkitAlignItems:((props: {received: boolean}) => props.received===true ? 'start' : 'end'),
  display: 'flex',
  alignItems: 'center',
})

const TimeStampBox = styled(Box)({
  textAlign: 'center',
  margin: '30px 0',
  position: 'relative',
})

const MessageContainer = styled(`div`)({
  minWidth: 0,
  wordWrap: 'break-word',
  flexDirection: ((props: {received: boolean}) => props.received===true ? 'row' : 'row-reverse'),
  display: 'flex',
  alignItems: 'center',
})

const SentMessageAttachment = styled(`p`)({
  color: '#454242',
  background: "rgba(211, 211, 211, 0.43)",
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "20px 0px 20px 20px",
  marginBottom:"14px",
})

const ReceivedMessageAttachment = styled(`p`)({
  color: '#454242',
  background: 'rgba(211, 211, 211, 0.43)',
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "0px 20px 20px 20px",
  marginBottom:"14px",
})

const SentMessage = styled(`p`)({
  color: 'white',
  background: "#A046FB",
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "20px 0px 20px 20px",
})

const ReceivedMessage = styled(`p`)({
  color: 'black',
  background: 'lightGray',
  alignSelf: "flex-start",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "0px 20px 20px 20px",
})