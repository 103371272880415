export const My_Calender = require("../assets/My_Calender.png");
export const Add_schedule = require("../assets/Add_schedule.png");
export const close = require("../assets/close-circle.png");
export const info = require("../assets/info-circle.png");
export const location = require("../assets/location.png");
export const down = require("../assets/arrow-down.png");
export const tick = require("../assets/tick.svg");
export const milestone = require("../assets/milestone.svg");
export const cal = require("../assets/cal.svg");
export const time = require("../assets/time.svg");
export const repeat = require("../assets/repeat.svg");
export const des = require("../assets/des.svg");
export const back = require("../assets/imagebutton_back.png");
export const image_text = require("../assets/image_text.png");
export const editIcon = require("../assets/editicon.svg");
export const deleteIcon = require("../assets/deleteicon.svg");
export const white_loc = require("../assets/white_location.png");