import React from "react";
import { Button } from "@material-ui/core";
import GenericFileUploadButtonController from "./GenericFileUploadButtonController";

class GenericFileUploadButton extends GenericFileUploadButtonController {
  render() {
    return (
      <>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file" 
          onChangeCapture={this.onFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button 
            style={{
              color: "#424141",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              textTransform: 'capitalize',
              borderRadius: "67px",
              border: "1px solid #E3E3E3",
              background: "rgba(255, 255, 255, 0.80)",
              padding: '0 10px'
            }} 
            component="span"
          >
            {this.props.text}
          </Button>
        </label>
      </>
    );
  }
}

export default GenericFileUploadButton;