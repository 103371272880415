// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  styled,
  Drawer,
  Button,
  DrawerProps,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
  Hidden,
} from "@material-ui/core";
import PatientController, { Props } from "./PatientController";
import { buttonCancel } from "../../email-account-registration/src/assets";
import { emailLogo } from "./assets";
import { idLogo } from "./assets";
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import GenericTabs from "../../../components/src/GenericTabs.web";
import CustomDataTable from "../../../components/src/CustomDataTable.web";
// Customizable Area End

// Customizable Area Start
export default class Patient extends PatientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  renderTableData = () => {
    return (
      <>
        {this.state.tableData?.rows?.length > 0 &&
          (<CustomDataTable
            navigation={this.props.navigation}
            tableData={{
              ...this.state.tableData,
              rows: this.state.tableData.rows.map((obj, index) => {
                const newObj = { ...obj };
                const keys = Object.keys(newObj);

                keys.forEach(key => {

                  let newArray ;
                  if (this.state.selectedTab === "Trail Visit Schedule") {
                    newArray = this.state.tableData.columns.filter(item => item.name.startsWith('V')).map(item => item.name);
                  } else {
                    newArray = this.state.tableData.columns.filter(item => item.name.startsWith('T')).map(item => item.name);
                  }
                  const tContent = (value: string) => {
                    let bgColor;
                    let border;

                    switch (newObj[key]) {
                      case "Attended":
                        bgColor = "#A046FB"
                        border = undefined
                        break;
                      case "Not Attended":
                        bgColor = "#7B7B7B"
                        border = undefined
                        break;
                      case "Remaining":
                        bgColor = "white"
                        border = "1px solid #1F3650"
                        break;
                      default:
                        break;
                    }

                    return <Box
                      key={index}
                      height={"20px"}
                      width={"20px"}
                      boxShadow={3}
                      bgcolor={bgColor}
                      borderRadius={3}
                      marginRight={2}
                      border={border}
                    />;
                  }
                  if (newArray.includes(key)) {
                    newObj[key] = tContent(newObj[key]);
                  }
                });

                return newObj;
              })
            }}
          />)}
      </>
    )
  }
  renderDrawerStyle = () => {
    return (
      <DrawerStyle
        anchor="right"
        open={this.state.addPatientDrawerOpen}
        onClose={this.handleCloseCancel}
      >
        <AddPatientModalStyle>
          {this.state.addPatientDrawerOpen && (
            <Container className="addContainer">
              <IconButton
                className="cancelBtn"
                data-test-id="cancel-tech"
                onClick={() => this.handleCloseCancel()} 
              >
                <img src={buttonCancel} alt="buttonCancel" />
              </IconButton>
              <Typography component={"span"} className="addPatientText">
                Add Patients
              </Typography>
              <Grid item xs={12}>
                <Box>
                  <Typography className="addPara">
                    Type in the patient's email address to enroll them in
                    the selected study.
                  </Typography>
                </Box>
              </Grid>

              {this.state.emailScreen && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="emailAdd"
                      placeholder="Patient's Email Address"
                      value={this.state.patientEmailAddress}
                      onChange={e =>
                        this.handlePatientEmailAddress(e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={emailLogo}
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "8px"
                              }}
                            />
                          </InputAdornment>
                        ),
                        className: "patientEmail"
                      }}
                      error={this.state.patientEmailAddressError !== ""}
                      helperText={this.state.patientEmailAddressError}
                    />
                  </Grid>
                  {
                    <Typography className="passwordError">
                      {this.state.patientErrorMsg}
                    </Typography>
                  }
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="emailAddC"
                      placeholder="Confirm Patient's Email Address"
                      id="newConfirmEmailAddress"
                      value={this.state.newConfirmPatientEmailAddress}
                      onChange={e =>
                        this.handleNewConfirmPatientEmailAddress(
                          e.target.value
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={emailLogo}
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "8px"
                              }}
                            />
                          </InputAdornment>
                        ),
                        className: "cPatientEmail"
                      }}
                      inputProps={{
                        "data-test-id": "newConfirmEmailAddress"
                      }}
                      error={
                        this.state.newConfirmPatientEmailAddressError !==
                        ""
                      }
                      helperText={
                        this.state.newConfirmPatientEmailAddressError
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disabled={
                        this.state.patientEmailAddress === "" ||
                        this.state.newConfirmPatientEmailAddress === "" ||
                        this.state.patientEmailAddressError !== "" ||
                        this.state.newConfirmPatientEmailAddressError !== ""
                      }
                      className={!this.state.patientEmailAddressError &&  !this.state.newConfirmPatientEmailAddressError && this.state.patientEmailAddress !== "" &&  this.state.newConfirmPatientEmailAddress !== "" ? "countinue" : "continueBtn"}
                      data-test-id="emailBtn"
                      onClick={() => this.handleContinueButton()}
                    >
                      {this.state.isLoader ? (
                        <CircularProgress
                          size={30}
                          style={{ color: "#A046FB" }}
                        />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Grid>
                </>
              )}

              {this.state.nameScreen && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      value={this.state.patientEmailAddress}
                      id="emailAddressDis"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={emailLogo}
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "8px"
                              }}
                            />
                          </InputAdornment>
                        ),
                        className: "patientEmailDis"
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      value={this.state.newConfirmPatientEmailAddress}
                      variant="outlined"
                      id="newConfirmEmailAddressDis"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={emailLogo}
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "8px"
                              }}
                            />
                          </InputAdornment>
                        ),
                        className: "cPatientEmailDis"
                      }}
                      inputProps={{
                        "data-test-id": "GHGHGnewConfirmEmailAddress"
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      justifyContent: "center",
                      marginTop: "12px",
                      display: "flex"
                    }}
                  >
                    <Typography className="needText" component={"span"}>
                      Need to modify the Email ID?{" "}
                    </Typography>

                    {this.state.emailScreen || (
                      <Typography
                        className="clickEditText"
                        component={"span"}
                        onClick={this.handleEdit}
                      >
                        {" "}
                        Click here to edit.
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="fullnameP"
                      variant="outlined"
                      placeholder="Patient's Full Name"
                      value={this.state.patinetFullName}
                      onChange={e =>
                        this.handlePatientFullName(e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              marginLeft: "13px",
                              paddingRight: "9px"
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                            >
                              <path
                                d="M8.5 3.5C8.5 5.23194 7.31118 6.5 6 6.5C4.68882 6.5 3.5 5.23194 3.5 3.5C3.5 1.76806 4.68882 0.5 6 0.5C7.31118 0.5 8.5 1.76806 8.5 3.5ZM2.80586 8.74006C3.27291 8.58207 3.77599 8.5 4.28571 8.5H7.71429C8.7474 8.5 9.72271 8.83602 10.4285 9.41239C11.1314 9.98647 11.5 10.7408 11.5 11.5V14.3C11.5 14.3166 11.4927 14.3587 11.4327 14.4077C11.3699 14.459 11.2667 14.5 11.1429 14.5H0.857143C0.733339 14.5 0.630098 14.459 0.567318 14.4077C0.507343 14.3587 0.5 14.3166 0.5 14.3V11.5C0.5 10.7408 0.868565 9.98647 1.57152 9.41239C1.91998 9.12782 2.33876 8.89806 2.80586 8.74006Z"
                                stroke="#1F4450"
                              />
                            </svg>
                          </InputAdornment>
                        ),
                        className: "pFullName"
                      }}
                      error={this.state.patinetFullNameError !== ""}
                      helperText={this.state.patinetFullNameError}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      data-test-id="patientIdNumber"
                      fullWidth
                      variant="outlined"
                      placeholder="Patient's ID Number"
                      value={this.state.patinetIdNumber}
                      onChange={e =>
                        this.handlePatientIdNumber(e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              marginLeft: "8px",
                              paddingRight: "2px"
                            }}
                          >
                            <img src={idLogo} alt="idLogo" />
                          </InputAdornment>
                        ),
                        className: "pIdNumber"
                      }}
                      error={this.state.patinetIdNumberError !== ""}
                      helperText={this.state.patinetIdNumberError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      data-test-id="patientDataBtn"
                      fullWidth
                      disabled={
                        this.state.patinetFullName === "" ||
                        this.state.patinetIdNumber === "" ||
                        this.state.patinetFullNameError !== "" ||
                        this.state.patinetIdNumberError !== ""
                      }
                      className={!this.state.patinetFullNameError && !this.state.patinetIdNumberError && this.state.patinetFullName !== "" && this.state.patinetIdNumber !== "" ? "countinue" : "addPatientBtn"}
                      onClick={() => this.handleAddPatientButton()}
                    >
                      {this.state.isLoader ? (
                        <CircularProgress
                          size={30}
                          style={{ color: "#A046FB" }}
                        />
                      ) : (
                        "Add Patient"
                      )}
                    </Button>
                  </Grid>
                </>
              )}
            </Container>
          )}
        </AddPatientModalStyle>
      </DrawerStyle>
    )
  }
  render() {
    return (
      // Customizable Area Start
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
        />

        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar
            navigation={this.props.navigation}
            id={this.props.id}
            selected="patient"
          />
          <Grid container spacing={2}>
          <Grid item xs={12} sm={9} >
          <PatientMainContainer >
            <Grid container  style={{marginBottom:'30px',justifyContent:'space-between',display:'flex',alignItems:'center'}}>
              <Grid >
              <Typography
                style={{
                  color: "#1F3650",
                  fontSize: 40,
                  fontWeight: 700,
                  fontFamily: "Jost",
                  lineHeight: "50px",
                  textAlign: "left"
                }}
              >
                Patients
              </Typography>
              </Grid>
              <Grid >
              <Button
                variant="outlined"
                onClick={this.handleAddPatDrawerOpen}
                style={{ border: "2px solid #A046FB", textTransform: 'none', fontFamily: "Jost",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "24px",color:'#000000' }}
                size="large"
              > + Add Patients </Button>
            </Grid>
            </Grid>
            <Typography
              style={{
                color: "#414141",
                fontSize: 14,
                fontWeight: 400,
                marginBottom: 50
              }}
              paragraph
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </Typography>

            <Grid container spacing={2}>    
            <Grid item xs={12} sm={6}>        
                                <GenericTabs
                tabs={this.state.tabs}
                handleSelectTab={this.handleSelectTab}
                selected={this.state.selectedTab}
              /></Grid>
             <Grid item xs={12} sm={6} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                {["Attended", "Not Attended", "Remaining"].map((item, index) => {
                  let bgColor;

                  switch (item) {
                    case "Attended":
                      bgColor = "#A046FB"
                      break;
                    case "Not Attended":
                      bgColor = "#7B7B7B"
                      break;
                    case "Remaining":
                      bgColor = "white"
                      break;
                    default:
                      break;
                  }
                  return <Grid container>
                  <Box
                    key={index}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Box
                      height={"12px"}
                      width={"12px"}
                      boxShadow={3}
                      bgcolor={bgColor}
                      borderRadius={3}
                      border={item === "Remaining" ? "1px solid #1F3650" : undefined}
                    />&nbsp;
                    <Typography style={{
                      color: "#414141",
                      fontSize: 12,
                      fontWeight: 400,
                    }}>{item}</Typography>
                  </Box>
                  </Grid>
                })}
              </Grid>
            </Grid>

            

            <Box width="100%" height={2} bgcolor="#EAE9E9" marginBottom={5} />
            {this.renderTableData()}
            {this.renderDrawerStyle()}


          </PatientMainContainer>
          </Grid>
          <Grid item xs={12} sm={3}>
          </Grid>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const DrawerStyle = styled(Drawer)<DrawerProps>({
  height: "calc(100vh - 90px)",
  "& .MuiDrawer-paper": {
    borderRadius: "10px 0 0 10px"
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "transparent"
  }
});

const drawerWidth = 470;
const AddPatientModalStyle = styled(Box)({
  "& .addContainer": {
    // position:'absolute',
    // top:'5px',
    // right:'-16px',
    paddingTop: "40px",
    marginLeft: "16px",
    maxWidth: "450px",
    width: "100%"
  },
  "& .MuiContainer-root": {
    paddingLeft: "20px",
    paddingRight: "38px"
  },

  "& .editDrawer": {
    width: drawerWidth,
    flexShrink: 0
  },
  "& .drawerPaper": {
    width: drawerWidth
  },
  "& .box": {
    display: "flex"
  },
  "& .addPatientText": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700
  },
  "& .addPara": {
    color: "#414141",
    fontFamily: "Jost",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400
  },
  "& .cancelBtn": {
    float: "right",
    marginRight: "-10px",
    cursor: "pointer",
    marginTop: "-20px"
  },
  "& .MuiAccordionSummary-root ": {
    // display: "flex",
    padding: "0px",
    paddingRight: "20px"
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none"
  },
  "& .MuiAccordionDetails-root": {
    padding: "0px"
  },

  "& .patientEmail": {
    marginTop: "20px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .cPatientEmail": {
    marginTop: "20px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .continueBtn": {
    marginTop: "26px",
    borderRadius: "4px",
    textTransform: "capitalize",
    color: "#959292",
    fontFamily: "Jost",
    textAlign: "center",
    fontSize: "16px",
    background: "#FFF",
    opacity: 0.5,
    fontStyle: "normal",
    fontWeight: 700,
    height: "51px",
    border: "2px solid #484848"
  },
  
  "& .countinue":{
    fontStyle: "normal",
    marginTop: "26px",
    fontFamily: "Jost",
    fontSize: "16px",
    borderRadius: "4px",
    color: "black",
    textAlign: "center",
    fontWeight: 700,
    opacity: 0.5,
    background: "#FFF",
    height: "51px",
    border: "2px solid black",
    textTransform: "capitalize",
  },
  "& .needText": {
    marginTop: "4px",
    color: "#767E85",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400
  },
  "& .clickEditText": {
    marginTop: "4px",
    cursor: "pointer",
    marginLeft: "4px",
    color: "#A046FB",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .pFullName": {
    marginTop: "24px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .pIdNumber": {
    marginTop: "24px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .addPatientBtn": {
    height: "51px",
    marginTop: "26px",
    textTransform: "capitalize",
    color: "#959292",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "4px",
    border: "2px solid #484848",
    opacity: 0.5,
    background: "#FFF"
  },
  "& .patientEmailDis": {
    marginTop: "24px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .cPatientEmailDis": {
    marginTop: "24px",
    height: "49px",
    fontFamily: "Jost"
  },
  "& .passwordError": {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",
    marginLeft: "14px"
  },
});
const PatientMainContainer = styled(Box)({
 
    flex: 1, 
    padding: 30,
    width:'100%',
    "@media (max-width: 500px)": {
      width: "60%",
      padding:25
    }
});

// Customizable Area End
