Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Please enter the password";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.emailPasswordLoginAPiEndPoint = "login/login";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.forgetEmailOTPConfirmationAPiEndPoint = "bx_block_forgot_password/otp_confirmations";

exports.emailRegex =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
exports.passwordTextError  ="Password must be unique"
// Customizable Area End