import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideNavbar from "../../../components/src/SideNavbar.web";
// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
} from "./Customisableuserprofiles2Controller";


export default class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
        <SideNavbar navigation={this.props.navigation} id={this.props.id} showStudy={false} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
