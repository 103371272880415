import React from "react";

// Customizable Area Start

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, styled } from "@material-ui/core";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { termsConds } = this.state
    return (
      <>
        <Dialog
          scroll="body"
          className="termsContainer"
          fullWidth
          id="termsCndtBtn"
          onClose={() => this.props.handleCloseTerms()}
          maxWidth={'lg'}
          open={true}
          aria-labelledby="responsive-dialog-title" >
          <TermsDialogStyle>
            <DialogActions>
              <IconButton id="cancel-terms" onClick={() => this.props.handleCloseTerms()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
              </IconButton>
            </DialogActions>
            <DialogTitle >
              <Typography className='termsText'>Terms and Condition</Typography>
            </DialogTitle>
            <DialogContent >
              <Box>
                <div className="dialogContent" dangerouslySetInnerHTML={{ __html: termsConds }}/>
              </Box>
            </DialogContent>
          </TermsDialogStyle>
        </Dialog>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const TermsDialogStyle = styled(Box)({
  "& .MuiDialogContent-root": {
    padding: '8px 40px'
  },
  "& .MuiDialogTitle-root": {
    padding: '0 40px'
  },
  "& .termsContainer": {
    maxWidth: '1100px',
    width: '100%',
  },
  " & .termsText": {
    fontWeight: 700,
    fontSize: "28px",
    fontFamily: "Jost",
    fontStyle: "normal",
    lineHeight: "38px",
    color: "#000",
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  "& .dialogContent": {
    fontFamily: "Inter",
    color: "#3D3C3C",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "26px",
    fontStyle: "normal",
  },
  "& .dialogContentPara": {
    marginBottom: '10px',
    color: "#3D3C3C",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "26px",
    fontStyle: "normal",
    paddingTop: '20px',
  },
})
// Customizable Area End
